// CONTROL.RESOURCES

$resources-bp: "medium";
$podHeight: 420px;

.c-resources {
	@include control("dark grey", "white");
	position: relative;
}

.c-resources__bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;

	background-color: $grey--light;

	@include bp($resources-bp) {
		.c-resources--right & {
			background-image: url("/content/images/interface/bg/white-circles-pattern-quarter-left.svg");
			background-position: bottom left;
		}
		.c-resources--left & {
			background-image: url("/content/images/interface/bg/white-circles-pattern-quarter-right.svg");
			background-position: bottom right;
		}
		background-repeat: no-repeat;
	}

	@include bp("large") {
		max-height: ( $lsu + $bsu + ($podHeight * 1.5) );
	}

	@include bp-below("large") {
		background-size: 200px;
	}
}

.c-resources__layout {
	@include layout-wrapper;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: $bsu;
	position: relative;
	z-index: 1;
	padding-bottom: $bsu;

	@include bp("medium") {
		grid-template-columns: repeat(1, 1fr);
		padding-bottom: $lsu;
		grid-gap: $lsu;
	}

	@include bp("large") {
		grid-template-columns: repeat(3, 1fr);
		padding-bottom: 0;
	}

	@include bp("extra-large") {
		grid-template-columns: repeat(2, 1fr);
	}
}

.c-resources__items-wrapper {
	grid-column: span 1;

	@include bp("large") {
		grid-column: span 2;
		margin-bottom: calc(-1 * var(--control-gap-bottom) / 2);
	}

	@include bp("extra-large") {
		grid-column: span 1;
	}
}

.c-resources__heading {
	@include control__heading;
}

.c-resources__body {
	@include font-base-18;
	border-left: 2px solid var(--secondary-a);
	padding-left: ( $ssu * 1.5);

	> p {
		margin-bottom: $bsu;
	}
}

.c-resources__link {
	display: flex;
	align-items: center;
	margin-bottom: $ssu;

	@include font-base-20sb;

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;
		padding-left: 0.5em;
		color: var(--secondary-a);
	}
}


.c-resources__items {
	.c-pod-flip__back {
		justify-content: flex-start;
	}
}


.c-pod__heading {

	&::after {
		.c-resources__items & {
			display: none;
		}
	}
}


.c-resources__list {
	@include list-reset;

	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	margin-left: -$ssu;
	margin-top: -$ssu;
}

.c-resources__item-layout {
	padding-left: $ssu;
	padding-top: $ssu;

	width: 100%;
}

.o-layout-content--has-aside {
	.c-resources__item-layout {
		width: 100%;
	}
}

.c-resources__item {
	display: flex;
	align-items: stretch;

	border: 1px solid $grey--light;

	@include bp-below($resources-bp) {
		flex-direction: column;
	}

	&[aria-busy="true"] {
		@include loader;
	}
}

.c-resources__media {
	@include figure;
	display: flex;
	align-items: stretch;

	@include bp($resources-bp) {
		width: 100px;
	}
}

.c-resources__details {
	flex-grow: 1;
	width: 100%;
	@include bp($resources-bp) {
		width: auto;
	}
}

.c-resources__brand {
	display: block;
	color: $brand;
	@include font-base-12b;
	margin-bottom: $tsu;
}

.c-resources__title {
	@include font-base-16b;
}

.c-resources__type-list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: $bsu;
	margin-top: calc($ssu - $ssu);
	@include bp($resources-bp) {
		margin-top: -$ssu;
	}

}

// Single type
.c-resources__type {
	margin-right: $bsu;
	margin-top: $ssu;
	@include bp($resources-bp) {
		margin-top: 0;
	}
}

// Multiple types
.c-resources__type-list .c-resources__type {
	margin-right: 0;
	margin-top: 0;

	padding-left: 15px;
	padding-top: $ssu;

	display: flex;
	align-items: center;
}

.c-resources__type-label {
	@include fake-input__wrapper;
	display: flex;
	align-items: center;
	cursor: pointer;

	.c-fake-checkbox__display {
		margin-right: $tsu;
	}
}

.c-resources__type-text {
	@include font-base-14;
}


.c-resources__actions {
	display: flex;
	align-items: center;

	margin-top: $ssu;
	@include bp($resources-bp) {
		margin-top: 0;
	}
}

.c-resources__action {
	@include button;
}
