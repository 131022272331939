// COMPONENT.PAGINATION



$pagination-bp: "medium";

.c-pagination {
	display: flex;
	@include font-base-14;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-top: $bsu;
	margin-bottom: $bsu;

	@include bp("large") {
		margin-top: $lsu;
		margin-bottom: $lsu;
	}
}

.c-pagination__desktop {
	flex-grow: 1;

	@include grid;

	@include bp-below($pagination-bp) {
		display: none;
	}
}

.c-pagination__summary {
	display: none;
	padding-left: $bsu;
	width: calc(100% / 3);
}

.c-pagination__body {
	padding-left: $bsu;
	width: 100%;
	text-align: center;
}

.c-pagination__list {
	@include list-reset;
	@include list-inline;
}

.c-pagination__mobile {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;

	@include bp($pagination-bp) {
		display: none;
	}
}

.c-pagination__current {
	padding: 0 $bsu;

	@include bp($pagination-bp) {
		padding: 0 $bsu;
	}
}

.c-pagination__dropdown {
	padding: $tsu;
	margin: 0 calc($tsu / 2);
}

// "&, a" because the class is sometimes on an <li> and sometimes on an <a>
.c-pagination__prev,
.c-pagination__number,
.c-pagination__next {
	&, a {
		@include link-transition;
		@include font-base-18;
		padding: 0 $tsu;
		white-space: nowrap;
	}

	&:first-child {
		&, a {
			padding-left: 0;
		}
	}

	&:last-child {
		&, a {
			padding-right: 0;
		}
	}
}

.c-pagination__prev,
.c-pagination__next {
	&, a {
		@include font-heading-20b;
		display: inline-flex;
		align-items: center;
	}
}

.c-pagination__number {
	&.is-selected {
		a {
			text-decoration: underline;
		}
	}
}

.c-pagination__icon {
	.c-pagination__prev & {
		margin-right: $tsu;
	}

	.c-pagination__next & {
		margin-left: $tsu;
	}
}

.c-pagination__nav-text {
	display: none;

	@include bp("small") {
		//display: inline;
	}
}
