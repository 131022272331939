// COMPONENT.PRODUCT-TILE

.c-product-tile {
	flex-direction: column;
	justify-content: space-between;
}

.c-product-tile__subheading {
	@include font-base-14b;
	display: block;
	word-wrap: break-word;
	margin-bottom: 5px;
}

.c-product-tile__body {
	flex: 1 0 auto;
}

.c-product-tile__actions {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0 $tsu;
}

.c-product-tile__btn {
	@include button;

	flex: 1 0 auto;
	margin: $tsu;
}