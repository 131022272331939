﻿// MACRO.MAP



.m-map {
	@include body-text__figure;
}

.m-map__embed {
	@include embed;
	padding-bottom: 66.66%;
}
