// COMPONENT.CTA-INTRO

.c-cta-intro {
	margin-bottom: $hsu;
}

.c-cta-intro__layout {
	@include layout-wrapper;

	@include bp("medium") {
		display: flex;
	}
}

.c-cta-intro__body {
	@include bp("medium") {
		padding-right: $lsu;
	}
}

.c-cta-intro__cta {
	margin: 0 auto;
	max-width: 320px;
	@include bp("medium") {
		flex-shrink: 0;
		width: 320px;
	}
}

.c-cta-intro__cta-figure {
	@include figure;
}

.c-cta-intro__cta-image {}

.c-cta-intro__cta-button {
	@include button;

	display: block;
	width: 100%;
	margin-top: $bsu;
}
