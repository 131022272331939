// PAGE STATEMENT

.c-page-statement {
	@include control;
}

.c-page-statement__layout {
	@include layout-wrapper;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: $bsu;
	grid-template-areas: "heading" "text" "body";

	@include bp("medium") {
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas: "heading heading" "text body";
	}
}

.c-page-statement__heading {
	@include font-heading-24b;
	grid-area: heading;


	@include bp("large") {
		@include font-heading-36b;
	}
}

.c-page-statement__text {
	@include font-heading-28b;
	line-height: 36px;
	grid-area: text;
	color: $brand;

	@include bp("large") {
		@include font-heading-32b;
	}

	@include bp("extra-large") {
		@include font-heading-40b;
	}


	&.c-page-statement--green {
		color: $green;
	}

	&.c-page-statement--blue {
		color: $blue;
	}

	&.c-page-statement--red {
		color: $red;
	}
}

.c-page-statement__body {
	@include font-base-18;
	line-height: 26px;
	grid-area: body;

	@include bp("extra-large") {
		@include font-base-20;
		line-height: 32px;
	}

	> p {
		margin-bottom: $ssu;

		&:last-child {
			margin-bottom: 0;
		}
	}

	a {
		@include link-brand;
		@include link-transition;
		@include font-base-18sb;

		@include bp("extra-large") {
			@include font-base-20sb;
		}
	}
}
