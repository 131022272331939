﻿// CONTROL.HEADING

.c-heading {
	@include control;

	margin: $lsu 0 $bsu;

	display: flex;
	align-items: flex-end;
}

.c-heading__main {
	flex-grow: 1;
}

.c-heading__link {
	white-space: nowrap;
}
