﻿// CONTROL.EMBED

.c-embed {
	@include control;
}

.c-embed__layout {
	@include layout-wrapper;
}

.c-embed__heading {
	@include control__heading;
}

.c-embed__content {
	max-width: 100%;
	// overflow: hidden;

	> iframe {
		width: 100%;
		max-width: 100%;
	}
}
