$layout-page-bp: "large";

$layout-page-gutter: calc(max(0px, (var(--page-width) - #{$layout-wrapper-max-width}) / 2));
$layout-page-aside-column-width: calc(#{$layout-aside-gutter-width} + #{$layout-aside-width} + #{$layout-page-gutter} + #{$bsu});

// Fallbacks first
// Be sure to override within @supports block if necessary
.l-page {}

.l-page__header {}

.l-page__main-controls {}

.l-page__sidebar {
	@include control;
	margin-left: var(--layout-gutter-right);
	margin-right: var(--layout-gutter-left);
	margin-top: $lsu;
	margin-top: calc(var(--control-gap-top) * 0.75);
	width: $layout-aside-width;
	--layout-gutter-left: 0;
	--layout-gutter-right: 0;

	.l-page__sidebar-sponsor + & {
		margin-top: 0;
	}
}

.l-page__sidebar-sponsor {
	@include control;
	margin-left: var(--layout-gutter-right);
	margin-right: var(--layout-gutter-left);
	width: $layout-aside-width;
	--layout-gutter-left: 0;
	--layout-gutter-right: 0;

	@include bp-below($layout-page-bp) {
		margin-bottom: 0;
	}
}

.l-page__secondary-controls {
	margin-top: var(--control-margin-top);

	@include bp-below($layout-page-bp) {
		.l-page__sidebar + & {
			margin-top: 0;
		}
	}
}

.l-page__pagination-controls {
    position: relative;

    @include bp($layout-page-bp) {
    	margin-top: #{$lsu * 1.5};
        margin-top: calc(var(--control-margin-top) * 1.5);
    }

    &::after {
        content: "";
        background-color: $grey--lightest;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: 0;

        @include bp("extra-large") {
            bottom: calc(var(--control-margin-top) / 2);
        }
    }

    @include bp-below($layout-page-bp) {

        .l-page__sidebar + & {
            margin-top: 0;
        }

        &::after {
            display: none;
        }
    }
}

.l-page__related-guides-controls {
    background-color: $grey--lightest;


    @include bp("extra-large") {
        margin-top: -$lsu;
    }

    @include bp-below($layout-page-bp) {
        margin-top: calc(-1 * var(--control-margin-top));
    }
}

.l-page__footer {
}
@supports (display: grid) {
	.l-page {
		display: grid;

		grid-template-areas:
			"header"
			"main-controls"
			"secondary-controls"
			"sidebar"
			"footer";

		@include bp($layout-page-bp) {
			grid-template-columns: 1fr;

			&.l-page--has-sidebar {
				grid-template-columns: 1fr $layout-page-aside-column-width;
				grid-template-areas:
					"header header"
					"main-controls sidebar"
					"secondary-controls secondary-controls"
					"footer footer";
			}
		}

		&.l-page--nature-detail {
			@include bp-below($layout-page-bp) {
				grid-template-areas:
					"header"
					"main-controls"
					"secondary-controls"
					"sponsor"
					"pagination-controls"
					"related-guides-controls"
					"sidebar"
					"footer";
			}

			@include bp($layout-page-bp) {
				grid-template-columns: 1fr;

				&.l-page--has-sidebar {
					grid-template-rows: auto auto 1fr auto auto auto auto;
					grid-template-columns: 1fr $layout-page-aside-column-width;
					grid-template-areas:
						"header header"
						"main-controls sponsor"
						"main-controls sidebar"
						"secondary-controls secondary-controls"
						"pagination-controls pagination-controls"
						"related-guides-controls related-guides-controls"
						"footer footer";
				}
			}
		}

		&.l-page--ced-detail {
			@include bp-below($layout-page-bp) {
				grid-template-areas:
					"header"
					"sidebar"
					"main-controls"
					"secondary-controls"
					"footer";
			}
		}
	}

	.l-page__header {
		grid-area: header;
	}

	.l-page__main-controls {
		grid-area: main-controls;

		.l-page--has-sidebar & {
			@include bp($layout-wrapper-max-width) {
				margin-left: $layout-page-gutter;
				--layout-gutter-right: 0;
			}
		}
	}

	.l-page__secondary-controls {
		grid-area: secondary-controls;

		.l-page__main-controls + & {
			// Simulate control gap margin collapsing
			// like with display: block;
			margin-top: calc(-1 * var(--control-margin-top));
		}
	}

	.l-page__pagination-controls {
		grid-area: pagination-controls;

		.l-page__main-controls + & {
			// Simulate control gap margin collapsing
			// like with display: block;
			//margin-top: calc(-1 * var(--control-margin-top));
		}
	}

	.l-page__related-guides-controls{
		grid-area: related-guides-controls;
	}

	.l-page__sidebar {
		grid-area: sidebar;

		@include bp($layout-page-bp) {
			width: $layout-aside-width;
		}

		width: auto;
		// If the sidebar is taller than the main controls,
		// ensure footer controls don't butt right up against it
		margin-bottom: var(--control-margin-bottom);
		@include bp($layout-page-bp) {
			.l-page__secondary-controls + & {
				// Ensure the secondary controls don't butt up right against the sidebar
				margin-bottom: calc(var(--control-margin-bottom) + var(--control-margin-top));
			}
		}

		@include bp($layout-page-bp) {
			margin-left: $layout-aside-gutter-width;
		}

		@include bp($layout-wrapper-max-width) {
			margin-right: calc(#{$layout-page-gutter} + #{$bsu});
		}
	}

	.l-page__sidebar-sponsor {
		grid-area: sponsor;

		@include bp($layout-page-bp) {
			margin-top: 0;
			margin-bottom: 0;
			width: $layout-aside-width;
		}

		width: auto;
		// If the sidebar is taller than the main controls,
		// ensure footer controls don't butt right up against it
		//margin-bottom: var(--control-margin-bottom);

		@include bp($layout-page-bp) {
			margin-left: $layout-aside-gutter-width;
		}

		@include bp($layout-wrapper-max-width) {
			margin-right: calc(#{$layout-page-gutter} + #{$bsu});
		}
	}

	.l-page__footer {
		grid-area: footer;
	}
}
