﻿// CONTROL.IMAGE-CAROUSEL

.c-image-carousel {
	@include control;
	@include clearfix;
}

.c-image-carousel__layout {
	@include layout-wrapper;
}

.c-image-carousel__header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: $bsu;
}

.c-image-carousel__heading {
	.c-image-carousel:not(.c-image-carousel--large) & {
		@include control__heading--small;
		margin-top: 15px;

		@include bp("large") {
			margin-top: 12px;
		}

		margin-bottom: 0;
	}

	.c-image-carousel.c-image-carousel--large & {
		@include control__heading;
		margin-top: 15px;

		@include bp("large") {
			margin-top: 5px;
		}

		margin-bottom: 0;
	}
}

.c-image-carousel__controls {
	display: flex;

	.c-image-carousel.is-inactive & {
		display: none;
	}
}

.c-image-carousel__control {
	@include button-reset;
	margin-left: $tsu;
	width: 60px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;

	&::before {
		@include font-icon;
		font-size: 40px;
	}

	@include link-transition;
}

.c-image-carousel__prev {
	&::before {
		content: $iconf-arrow-left;
	}
}

.c-image-carousel__next {
	&::before {
		content: $iconf-arrow-right;
	}
}

.c-image-carousel__body {
	@include layout-wrapper;
	overflow: hidden;
}

.c-image-carousel__items {
	@include list-reset;
	padding: 0;
	max-width: 1920px;

	.flickity-page-dots .dot {
		background: $white;
		box-shadow: inset 0 0 0 1px $black;
		box-shadow: inset 0 0 0 1px var(--primary-a);

		@include link-transition;

		&.is-selected {
			background: $black;
			background: var(--secondary-a);
			box-shadow: none;
		}
	}
}

.c-image-carousel__items .flickity-slider {
	display: flex;
	justify-content: center;
	align-items: center;
}

.c-image-carousel__items .flickity-button {
	display: none;

	@include bp("medium") {
		display: block;
	}
}

.c-image-carousel__items .flickity-page-dots {
	@include bp("medium") {
		display: none;
	}
}

.c-image-carousel__item {
	display: inline-block;
	width: 50%;
	padding: $ssu $bsu $ssu 0;

	@include bp("medium") {
		padding: $bsu;
		padding: $bsu $lsu $bsu 0;
		width: 25%;

		.c-image-carousel--large & {
			width: 33.33%;
		}

		float: left;
	}

	@include bp("large") {
		width: 20%;

		.c-image-carousel--large & {
			width: 25%;
		}

		float: left;
	}
}

.c-image-carousel__slide-link {
	@include figure;
}

.c-image-carousel__image {
	background-color: transparent !important;

	img {
		object-fit: contain;
	}
}

.c-image-carousel {
	// Extra specificity needed
	.c-image-carousel__image {
		padding-bottom: 50%;
	}
}
