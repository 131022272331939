// COMPONENT.HERO-NO-HERO

.c-hero--no-hero {
	position: relative;
	background-color: $grey--lightest;
	margin-bottom: 0;

	// Create stacking context
	isolation: isolate;
	transform: scale(1);
}

.c-hero__primary {
	.c-hero--no-hero & {
		background-color: $grey--lightest;
		background-image: url("/content/images/interface/bg/white-circles-pattern-quarter-left.svg");
		background-position: bottom left;
		background-repeat: no-repeat;
		margin-bottom: 0;


		@include bp("large") {
			margin-bottom: 0;
		}

		@include bp-below("large") {
			background-size: 200px;
		}

		.c-content-type-cedsolutions & {
			// The CED page filter will take up some space at the bottom
			padding-bottom: $lsu;
		}
	}
}

.c-hero__primary .c-hero__body {
	.c-hero--no-hero & {
		margin-bottom: 0;

		@include bp-below("large") {
			padding: 0;
		}

		@include bp("large") {
			margin-bottom: 0;
		}
	}
}

.c-hero--no-hero__back-link {
	display: block;
	@include link-transition;

	@include bp-below($layout-wrapper-max-width) {
		@include font-base-16sb;
		margin-bottom: $ssu;
	}

	@include bp($layout-wrapper-max-width) {
		@include font-base-20sb;
		margin-bottom: $bsu;
	}

	&::before {
		@include font-icon;
		content: $iconf-arrow-left;
		display: inline-block;
		vertical-align: center;
		margin-right: $tsu;
		color: var(--secondary-a);
	}


	@include bp($layout-wrapper-max-width) {
		transform: translateX(calc(-1em - #{$tsu}));
	}
}

.c-hero--no-hero__title {
	display: block;

	@include font-heading-28b;
	margin-top: $ssu;
	margin-bottom: ($ssu * 1.5);

	@include bp("large") {
		@include font-heading-40b;
		margin-bottom: $ssu;
	}

	@include bp("extra-large") {
		@include font-heading-52b;
	}
}

.c-hero--no-hero__back-link + .c-hero--no-hero__title {
	margin-top: 0;
}


.c-hero--no-hero__intro {
	@include font-base-18;
	line-height: lh(28, 18);

	@include bp("large") {
		@include font-base-20;
		line-height: lh(32, 20);
	}
}
