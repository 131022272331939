﻿// CONTROL.MAILCHIMP

// Reference material here: https://mailchimp.com/help/css-hooks-for-customizing-forms/
// We want to be able to copy and paste Mailchimp embed code without having to modify the markup
// The following CSS applies styles to the Mailchimp markup using their prescribed CSS hooks

.c-mailchimp {
	text-align: center;
}

#mc_embed_signup {}
#mc-embedded-subscribe-form {

	> div {

		@include bp('large') {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			justify-content: center;
		}
	}

	h2 {
		@include font-heading-32b;
		width: 100%;
		margin-bottom: $bsu;
	}
}

div.mc-field-group {
	margin-bottom: $ssu;
	text-align: left;

	@include bp('large') {
		margin-right: $bsu;
		margin-bottom: 0;
	}
}

div.mc-field-group label {
	display: block;
	margin-bottom: 5px;
}

div.mc-field-group input {
	@include font-base-16;
	width: 100%;
	padding: 0.65em;
	border: 1px solid $grey;
}

div.mc-field-group select {}

input.button {
	@include font-base-14u;
	@include link-transition;
	display: inline-block;
	padding: $bsu;
	border: 0;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	color: $white;
	background-color: $brand;

	&:hover,
	&:focus {
		background-color: darken($brand, 10);
	}
}

// Checkboxes and Radio buttons
div.mc-field-group.input-group {}
div.mc-field-group.input-group {}
div.mc-field-group.input-group ul {}
div.mc-field-group.input-group ul li {}
div.mc-field-group.input-group label {}
div.mc-field-group.input-group input {}

// Required Fields
.indicates-required {
	@include font-base-14;
	width: 100%;
	margin-bottom: $bsu;
}

.indicates-required .asterisk {
	color: $red;
}

.mc-field-group .asterisk {
	color: $red;
}

// Response Messages (Ajax)
#mce-responses {}
div.response {}
div.mce_inline_error {}
input.mce_inline_error {}
#mce-error-response {}
#mce-success-response {}

// Date Fields

.datefield {}
.datefield input {}
.datefield .monthfield input {}
.datefield .dayfield input {}
.datefield .yearfield input {}
.datefield .small-meta {}

// Address Fields
.mc-address-group {}

// US Phone Fields
.phonefield-us {}
.phonefield-us .phonearea {}
.phonefield-us .phonedetail1 input {}
.phonefield-us .phonedetail2 input {}
.phonefield-us .small-meta {}