// COMPONENT.RELATED-ITEMS

.c-related-items {
	@include control;
}

.c-related-items__layout {
	@include layout-wrapper;
}

.c-related-items__heading {
	@include control__heading;
}

.c-related-items__intro {
	@include body-text;

	margin-bottom: $bsu;

	p {
		@include font-base-16;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.c-related-items__list {
	@include list-reset;
	@include list-stacked;
}

.c-related-item {
	margin-bottom: $tsu;
}

.c-related-item__link {
	@include link-transition;
	position: relative;
	display: block;
	padding: $ssu $ssu;
	padding-left: $bsu * 2;
	//border: 1px solid $base-ui-color;
	background-color: $grey--light;

	&:focus:not(:focus-visible) {
		outline: none;
	}

	&:hover,
	&:focus {
		border-color: $brand;
		background-color: var(--primary-a);
		color: var(--primary-b);
	}
}

.c-related-item__text {
	@include font-heading-20b;
	@include link-transition;
	color: var(--primary-a);

	.c-related-item__link:hover &,
	.c-related-item__link:hover & {
		color: var(--primary-b);
	}
}

.c-related-item__icon {
	@include abs-center-v;
	left: $bsu;
	font-size: 22px;
	color: $brand;
}

.c-related-item__meta {
	@include font-base-10;
	@include link-transition;
	display: block;
	margin-top: calc($tsu / 2);
	color: var(--primary-a);

	.c-related-item__link:hover &,
	.c-related-item__link:hover & {
		color: var(--primary-b);
	}
}
