$news-bp: "medium";

.c-news {
	&:not(.c-news--grey-background) {
		@include control;
	}
	&.c-news--grey-background {
		@include control("grey");

		@include bp($news-bp) {
			&.c-news--circle-pattern {
				background-image: url("/content/images/interface/bg/white-circles-pattern-quarter-left.svg");
				background-position: bottom left;
				background-repeat: no-repeat;
			}
			@include bp-below("large") {
				background-size: 200px;
			}
		}
	}
}

.c-news__layout {
	@include layout-wrapper;
	@include bp-below("medium") {
		margin-left: $tsu;
		margin-right: $tsu;
		padding-left: 0;
		padding-right: 0;
	}
}

.c-news__header {
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	margin-bottom: $bsu;
}

.c-news__heading {
	@include control__heading;
	margin-bottom: 0;
}

.c-news__all-link-wrapper {
	display: flex;
	@include bp-below($news-bp) {
		margin-top: $bsu;
		justify-content: center;
	}
	@include bp($news-bp) {
		justify-content: flex-end;
		// Adjust for line height, to align bottom with heading
		margin-bottom: 3px;
	}

	&:first-child:last-child {
		width: 100%;
	}
}

.c-news__all-link-wrapper--mobile {
	@include bp($news-bp) {
		display: none;
	}
}

.c-news__all-link-wrapper--desktop {
	@include bp-below($news-bp) {
		display: none;
	}
}

.c-news__all-link {
	display: flex;
	align-items: center;

	@include font-base-20sb;

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;

		padding-left: 0.5em;

		color: var(--secondary-a);
	}
}

.c-news__body {}

.c-news__list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;

	margin-left: -$ssu;
	margin-top: -$ssu;
}

.c-news__list-item {
	padding-left: $ssu;
	padding-top: $ssu;

	width: 100%;
	@include bp("medium") {
		width: 50%;
	}
	@include bp("large") {
		width: 25%;
	}
	&:nth-child(1) {
		width: 100%;
		@include bp("large") {
			width: 50%;
		}

		@include bp-below("medium") {
			@include news-list-item("small");
		}
		@include bp("medium") {
			@include news-list-item("large");
		}
	}
	&:nth-child(n+2) {
		@include news-list-item("small");
	}
}

@supports (display: grid) {
	.c-news__list {
		display: grid;

		margin: 0;
		grid-gap: $ssu;

		grid-template-columns: 100%;
		@include bp("medium") {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		@include bp("large") {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}
	}

	.c-news__list-item {
		padding: 0;
		width: 100%;

		&:nth-child(1) {
			width: 100%;

			@include bp("medium") {
				grid-column-end: span 2;
			}
			@include bp("large") {
				grid-row-end: span 2;
			}
		}
	}
}