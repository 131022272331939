// COMPONENT.ACCOMMODATION-TILE

.c-accommodation-tile {
	display: flex;
	height: 100%;
	@include ui-border('all');
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	background-color: $white;
}

.c-accommodation-tile__figure {
}

.c-accommodation-tile__highlight {
	@include font-base-10b;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	padding: $tsu $ssu;
}

.c-accommodation-tile__info {
	display: flex;
	height: 100%;
	width: 100%;
	padding: $bsu;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	background: linear-gradient(to bottom, rgba($black,0) 0%, rgba($black,0.3) 50%, rgba($black,1) 85%);
	position: absolute;
	top: 0;
	left: 0;
}

.c-accommodation-tile__body {
	margin-bottom: $bsu;
}

.c-accommodation-tile__body-text {
	@include link-transition;
	height: 0;
	overflow: hidden;

	.c-accommodation-tile:hover & {
		height: auto;
		overflow: inherit;

	}
}

.c-accommodation-tile__price-lable {
	margin-bottom: $tsu;
}

.c-accommodation-tile__price {
	margin-bottom: $bsu;
}

// When there is no figure
.c-accommodation-tile__highlight:first-child + .c-accommodation-tile__body {
	margin-top: $bsu;
}

.c-accommodation-tile__body {}

.c-accommodation-tile__subheading {
	display: block;
	margin-bottom: $ssu;

	@include font-base-14b;
	color: $body-color;
	word-wrap: break-word;
}

.c-accommodation-tile__heading {
	display: block;
	margin-bottom: $ssu;

	@include font-base-20b;
	@include link-transition;
}

.c-accommodation-tile__text {
	display: block;

	@include font-base-16;
	color: $body-color;

	> :last-child {
		margin-bottom: 0;
	}
}

.c-accommodation-tile__actions {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.c-accommodation-tile__btn {
	@include button;
	display: block;
	width: 100%;

	flex: 1 0 auto;
}

