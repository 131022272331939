// CONTROL.CASE-STUDY



$case-study-bp: "large";

.c-case-study {
	@include control;

	@include bp($case-study-bp) {
		display: flex;
		align-items: stretch;
	}
}

.c-case-study__layout {
	@include layout-wrapper;
}

.c-case-study__figure {
	@include figure;

	@include bp($case-study-bp) {
		flex: 1 0 50%;
	}
}

.c-case-study__image {
}

.c-case-study__body {
	@include font-base-16;
	padding: $bsu;
	background-color: $grey--lightest;

	@include bp($case-study-bp) {
		flex: 1 0 50%;
		padding: $lsu;
	}

	> :last-child {
		margin-bottom: 0;
	}
}

.c-case-study__heading {
	margin-bottom: $bsu;

	@include font-base-20b;

	@include bp($case-study-bp) {
		@include font-base-28b;
	}
}

.c-case-study__text {
	@include body-text;
	margin-bottom: $bsu;
}

.c-case-study__video {
	@include embed;
	display: block;
	cursor: pointer;
	padding-bottom: 56.25%;
}

.c-case-study__video-icon {
	@include abs-center;
	width: 66px;
	height: 54px;
	@include link-transition;

	&::before {
		@include pseudo-element;
		left: 22px;
		top: 17px;
		border-width: 10px 20px;
		border-color: transparent;
		border-left-color: $white;
		border-style: solid;
	}

	@include bp("small") {
		width: 76px;
		height: 62px;

		&::before {
			left: 25px;
			top: 18px;
			border-width: 13px 26px;
		}
	}

	@include bp("medium") {
		width: 90px;
		height: 76px;

		&::before {
			left: 30px;
			top: 23px;
			border-width: 15px 30px;
		}
	}
}

.c-case-study__link {
	@include button;
}
