﻿// CONTROL.IMAGE-FEATURE

@mixin image-feature--wide {
	.c-image-feature__layout {
		display: flex;
		padding: 0;
	}

	.c-image-feature__layout--right {
		flex-flow: row-reverse;

		.c-image-feature__content {
			padding: ($bsu * 3) ($bsu * 2);

			@include bp("large") {
				@include layout-split(left, 50%);
			}
		}
	}

	.c-image-feature__figure {
		flex: 0 0 50%;
		align-self: stretch;
		margin-bottom: 0;
	}

	.c-image-feature__image {
		height: 100%;
	}

	.c-image-feature__content {
		padding: ($bsu * 3) ($bsu * 2);

		@include bp("large") {
			@include layout-split(right, 50%);
		}
	}
}

.l-page:not(.l-page--has-sidebar) {
	@include bp("large") {
		@include image-feature--wide;
	}
}

.l-page--has-sidebar {
	@include bp("extra-large") {
		@include image-feature--wide;
	}
}

.c-image-feature {
	@include control;
	margin-bottom: 0;

	+ * {
		--last-control-was-flush: 1;
	}
	// Fallback
	+ .c-image-feature {
		margin-top: 0;
	}
	// Remove top spacing if previous control was flush, or if it if the previous control had a grey or dark grey background
	margin-top: calc(var(--control-gap-top) * (1 - var(--last-control-was-flush)) * (1 - var(--last-control-was-grey)) * (1 - var(--last-control-was-dark-grey)));
}

.c-image-feature__layout-wrapper {
	//@include layout-wrapper;
	width: 100%;
}

.c-image-feature__layout {
	background-color: $grey--lightest;
}

.c-image-feature__layout--right {
}

.c-image-feature__figure {
	@include figure;
}

.c-image-feature__image {
	// Extra specificity to override crop rule
	.c-image-feature & {
		padding-bottom: 56.25%;
	}
}

.c-image-feature__body {
	display: flex;
	align-items: center;

	@include bp("large") {
		width: 50%;

		.l-page--has-sidebar & {
			width: 100%;
		}
	}

	> *:last-child,
	&:last-child {
		margin-bottom: 0;
	}
}

.c-image-feature__content {
	padding: $bsu;
	text-align: left;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;

	@include bp("large") {
		padding: $bsu * 4;

		.l-page--has-sidebar &,
		.l-page--has-sidebar .c-image-feature__layout--right & {
			padding: ($bsu * 3) ($bsu * 2);
		}
	}
}

.c-image-feature__heading {
	@include font-heading-24b;
	position: relative;
	margin-bottom: 0.5em;
	color: $brand;

	@include bp("large") {
		@include font-heading-36b;
		margin-bottom: 1.35em;
	}
}

.c-image-feature__heading-eyebrows {
	@include font-base-16;
	margin-bottom: $bsu;
}

.c-image-feature__text {
	@include font-base-16;
	margin-bottom: $bsu;

	@include bp("large") {
		margin-bottom: $bsu * 1.5;
	}

	&:last-child {
		margin-bottom: 0;

		> p:last-child {
			margin-bottom: 0;
		}
	}
}

.c-image-feature__link {
	display: flex;
	align-items: center;

	@include font-base-20sb;

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;
		padding-left: 0.5em;
		color: var(--secondary-a);
	}
}
