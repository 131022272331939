﻿// COMPONENT.VIDEO-GALLERY



.c-video-gallery {
}

.c-video-gallery__screen {
	@include embed;
	padding-bottom: 56.25%;
}

.c-video-gallery__screen-poster {
	display: block;
	position: relative;

	&.is-playing {
		display: none;
	}
}

.c-video-gallery__screen-poster-icon {
	@include button("medium", true);
	@include abs-center;

	&::before {
		@include font-icon;
		content: $iconf-play;
	}
}

.c-video-gallery__screen-embed {
}

.c-video-gallery__thumbnail-list {
	@include list-reset;

	display: flex;
	margin-left: -$bsu;
}

.c-video-gallery__thumbnail {
	padding-left: $bsu;
	padding-top: $bsu;
	flex: 0 1 auto;
	width: 25%;
}

.c-video-gallery__thumbnail-link {
	display: block;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba($black, 0.2);
		pointer-events: none;

		@include link-transition(background);
	}

	&:hover,
	&:focus,
	&[aria-current="true"] {
		&::before {
			background: rgba($black, 0);
		}
	}
}

.c-video-gallery__thumbnail-image {
	display: block;
	max-width: 100%;
}

.c-video-gallery__thumbnail-icon {
	@include button("smallest", true);
	@include abs-center;

	&::before {
		@include font-icon;
		content: $iconf-play;
	}
}
