// COMPONENT.TOOLTIP

$tooltip-wiggle-room: 18px;

.c-tooltip {
	display: inline-block;
	position: relative;

	&:focus {
		outline: none;
	}

	&::after {
		content: '?';
		width: 15px;
		height: 15px;
		display: inline-flex;
		background-color: $link-hover;
		color: $primary-a;
		font-size: 12px;
		border-radius: 50%;
		justify-content: center;
		align-items: center;
		transform: translate(-5px, -100%);
	}

	&.c-ced-pod__icon-tooltip,
	&.c-solution-aside__icon-tooltip {
		&::after {
			display: none;
		}
	}
}

.c-tooltip__trigger {
	position: relative;
	cursor: pointer;
	// This makes it easier to confidently position the tooltip body relative to the text
	white-space: nowrap;
	color: var(--link);
	//text-decoration-line: underline;
	//text-decoration-style: dotted;
	//text-decoration-color: var(--link--hover);
	background-color: rgba($link-hover, 0.1);
	font-style: italic;

	p & {
		@include font-body-link-text;
	}

	
}

@mixin tooltip-trigger {
	&::before {
		content: "";
		position: absolute;

		left: 0;
		right: 0;
		top: -($tooltip-wiggle-room);
		bottom: -($tooltip-wiggle-room);
	}
}

// Separate out :focus-within so browsers that don't recognise
// the pseudoclass won't also ignore the :hover, :focus, or :focus-visible rules
.c-tooltip__trigger:hover,
.c-tooltip:focus .c-tooltip__trigger {
	@include tooltip-trigger;
}
.c-tooltip:focus-visible .c-tooltip__trigger {
	@include focus-outline;
}
.c-tooltip:focus-within .c-tooltip__trigger {
	@include tooltip-trigger;
}

.c-tooltip__body {
	position: absolute;
	z-index: 1;

	background: $brand;
	background: var(--primary-a);
	color: $white;
	color: var(--primary-b);
	filter: drop-shadow(0 0 1px $white);
	filter: drop-shadow(0 0 1px var(--primary-b));

	@include font-base-14;
	a {
		@include font-base-14sb;
	}

	padding: $ssu;

	@include bp-below("small") {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
	}
	@include bp("small") {
		top: 100%;
		left: 50%;
		transform: translate(-50%, $tsu);

		width: 250px;

		&::after {
			content: "";

			position: absolute;
			bottom: 100%;
			right: 50%;
			transform: translateX(50%);
			width: 0;
			height: 0;

			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-bottom: 7px solid $brand;
			border-bottom-color: var(--primary-a);
		}

		.c-tooltip--left & {
			left: 0;
			transform: translate(0, $tsu);
			&::after {
				right: auto;
				left: $tsu;
			}
		}
		.c-tooltip--right & {
			left: auto;
			right: 0;
			transform: translate(0, $tsu);
			&::after {
				right: $tsu;
			}
		}
	}

	// Wiggle room
	&::before {
		content: "";
		position: absolute;
		z-index: -1;

		left: -($tooltip-wiggle-room);
		right: -($tooltip-wiggle-room);
		top: -($tooltip-wiggle-room);
		bottom: -($tooltip-wiggle-room);
	}

	display: none;
}
.c-tooltip__body:hover,
.c-tooltip:focus .c-tooltip__body,
.c-tooltip:hover .c-tooltip__body {
	display: block;
}
.c-tooltip:focus-within .c-tooltip__body {
	display: block;
}
.c-tooltip.c-tooltip--hidden {
	.c-tooltip__body {
		display: none;
	}
}

.c-tooltip__close {
	@include button-reset;
	color: inherit;

	position: absolute;
	top: 5px;
	right: 5px;

	&::before {
		@include font-icon;
		content: $iconf-close;
	}
}

.c-tooltip__body-title {
	display: block;
	margin: 0 0 $ssu;
	&:last-child {
		margin-bottom: 0;
	}

	@include font-base-16sb;
}
a.c-tooltip__body-title {
	&::after {
		@include font-icon;
		content: $iconf-arrow-right;

		display: inline-block;
		vertical-align: middle;
		margin-left: 5px;
	}
}

.c-tooltip__body-paragraph {
	display: block;
	margin: 0 0 $ssu;
	&:last-child {
		margin-bottom: 0;
	}
}
