﻿// CONTROL.HIGHLIGHT-TEXT

$highlights-bp: "medium";

.c-highlight-text {
	@include control;
}

.c-highlight-text__layout {
	@include layout-wrapper;
}

.c-highlight-text__wrapper {
    @include font-base-16;
    border-left: 2px solid $primary-a;
    background-color: rgba($primary-a, 0.05);
    padding: ($tsu * 1.5) $ssu;

    @include bp("large") {
        @include font-base-18;
        padding: ($ssu * 1.5) $bsu;
    }

    &.t-bg--green {
        border-left: 2px solid $secondary-a;
        background-color: rgba($secondary-a, 0.05);
    }

    &.t-bg--red {
        border-left: 2px solid $supporting-2-a;
        background-color: rgba($supporting-2-a, 0.05);
    }

    &.t-bg--blue {
        border-left: 2px solid $supporting-1-a;
        background-color: rgba($supporting-1-a, 0.05);
    }

    > p:last-child {
        margin-bottom: 0;
    }

    a {
        color: var(--link);
        text-decoration-line: underline;
        text-decoration-color: var(--link--hover);
        @include font-body-link-text;

        &:hover,
        &:focus {
            color: var(--link--hover);
        }
    }
}

.c-highlight-text__heading {
	@include font-heading-16b;
	margin-bottom: $ssu;

	@include bp("large") {
		@include font-heading-20b;
	}
}
