﻿// COMPONENT.SEARCH-RESULT

.c-search-result {
	@include control;
}

.c-search-result__layout {
	@include layout-wrapper;
}

.c-search-result__heading {
	@include control__heading;
}

.c-search-result__item {}

.c-search-result__wrapper {
	@include layout-wrapper;
}

.c-search-result__body {}

.c-search-result__heading {
	@include font-heading-24b;
	display: block;
	margin-bottom: $ssu;
	color: $body-color;
}



.c-search-result__meta {
	@include font-base-12;
	display: block;
	margin: $ssu 0;
	color: $body-color;
}

.c-search-result__category {
	display: inline-block;
	padding: 0.5em 1em;
	margin-left: $ssu;
	color: $white;
	background-color: $brand;
}

.c-search-result__text {
	@include font-base-16;
	display: block;
	color: $body-color;

	&[draggable="false"] {
		display: inline;
	}
}
.c-search-result__url {
	@include font-base-12;
	margin: $ssu 0;

}