﻿// MACRO.VIDEO



.m-video {
	@include body-text__figure;
}

.m-video__embed {
	@include embed;
	padding-bottom: 56.25%;
}

.m-video__figcaption {
	@include body-text__figcaption;
}
