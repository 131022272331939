// BASE.TYPOGRAPHY

// Config
//-------
// Base settings all set on the HTML element
// $base-font-size is used throughout to calculate em/rem sizes
$base-font-family: "Work Sans", Arial, Helvetica, sans-serif;
$heading-font-family: "PT Serif", Arial, Helvetica, sans-serif;
$highlights-font-family: "bebas-kai", Arial, Helvetica, sans-serif;

$base-font-size: 16px;
$base-line-height: 26px;

$weight--light: 300;
$weight--normal: 400;
$weight--medium: 500;
$weight--semibold: 600;
$weight--bold: 700;


// PT Serif
//
// URL: https://fonts.google.com/specimen/PT+Serif?query=PT+Serif
// Transformed: Generated by Font Squirrel (https://www.fontsquirrel.com) on December 6, 2021
//
// Licence: SIL OPEN FONT LICENSE Version 1.1
// Licence URL: /Content/fonts/PT_Serif/OFL.txt

@font-face {
	font-family: "PT Serif";
	src: url("/content/fonts/pt-serif/pt-serif-regular.eot");
	src: url("/content/fonts/pt-serif/pt-serif-regular.eot?#iefix") format("embedded-opentype"),
		url("/content/fonts/pt-serif/pt-serif-regular.woff2") format("woff2"),
		url("/content/fonts/pt-serif/pt-serif-regular.woff") format("woff"),
		url("/content/fonts/pt-serif/pt-serif-regular.ttf") format("truetype"),
		url("/content/fonts/pt-serif/pt-serif-regular.svg#ptserifratregular") format("svg");
	font-weight: $weight--normal;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: "PT Serif";
	src: url("/content/fonts/pt-serif/pt-serif-italic.eot");
	src: url("/content/fonts/pt-serif/pt-serif-italic.eot?#iefix") format("embedded-opentype"),
		url("/content/fonts/pt-serif/pt-serif-italic.woff2") format("woff2"),
		url("/content/fonts/pt-serif/pt-serif-italic.woff") format("woff"),
		url("/content/fonts/pt-serif/pt-serif-italic.ttf") format("truetype"),
		url("/content/fonts/pt-serif/pt-serif-italic.svg#ptserifratitalic") format("svg");
	font-weight: $weight--normal;
	font-style: italic;
	font-display: auto;
}

@font-face {
	font-family: "PT Serif";
	src: url("/content/fonts/pt-serif/pt-serif-bold.eot");
	src: url("/content/fonts/pt-serif/pt-serif-bold.eot?#iefix") format("embedded-opentype"),
		url("/content/fonts/pt-serif/pt-serif-bold.woff2") format("woff2"),
		url("/content/fonts/pt-serif/pt-serif-bold.woff") format("woff"),
		url("/content/fonts/pt-serif/pt-serif-bold.ttf") format("truetype"),
		url("/content/fonts/pt-serif/pt-serif-bold.svg#ptserifratbold") format("svg");
	font-weight: $weight--bold;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: "PT Serif";
	src: url("/content/fonts/pt-serif/pt-serif-bolditalic.eot");
	src: url("/content/fonts/pt-serif/pt-serif-bolditalic.eot?#iefix") format("embedded-opentype"),
		url("/content/fonts/pt-serif/pt-serif-bolditalic.woff2") format("woff2"),
		url("/content/fonts/pt-serif/pt-serif-bolditalic.woff") format("woff"),
		url("/content/fonts/pt-serif/pt-serif-bolditalic.ttf") format("truetype"),
		url("/content/fonts/pt-serif/pt-serif-bolditalic.svg#ptserifratbolditalic") format("svg");
	font-weight: $weight--bold;
	font-style: italic;
	font-display: auto;
}

// Work Sans
//
// URL: https://fonts.google.com/specimen/Work+Sans?query=Work+Sans
//
// Licence: SIL OPEN FONT LICENSE Version 1.1
// Licence URL: /Content/fonts/Work_Sans/OFL.txt

@font-face {
	font-family: "Work Sans";
	src: url("/content/fonts/work-sans/work-sans-regular.ttf") format("truetype");
	font-weight: $weight--normal;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: "Work Sans";
	src: url("/content/fonts/work-sans/work-sans-italic.ttf") format("truetype");
	font-weight: $weight--normal;
	font-style: italic;
	font-display: auto;
}

@font-face {
	font-family: "Work Sans";
	src: url("/content/fonts/work-sans/work-sans-medium.ttf") format("truetype");
	font-weight: $weight--medium;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: "Work Sans";
	src: url("/content/fonts/work-sans/work-sans-mediumitalic.ttf") format("truetype");
	font-weight: $weight--medium;
	font-style: italic;
	font-display: auto;
}

@font-face {
	font-family: "Work Sans";
	src: url("/content/fonts/work-sans/work-sans-semibold.ttf") format("truetype");
	font-weight: $weight--bold;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: "Work Sans";
	src: url("/content/fonts/work-sans/work-sans-semibolditalic.ttf") format("truetype");
	font-weight: $weight--bold;
	font-style: italic;
	font-display: auto;
}

// Bebas Kai
//
// URL: https://fonts.adobe.com/fonts/bebas-kai
// Adobe account: registrations@terabyte.co.nz
// Adobe project: SBN
//
// font-family: "bebas-kai";
// font-weight: 400;
// font-style: normal;

@import "https://use.typekit.net/imx5mjy.css";

//////////

// Style declarations
//
//	.c-component__text {
//		@include font-base-12;
//	}

@mixin font-body-text {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--normal;
	line-height: lh(26, 16);

	@include bp("large") {
		font-size: 18px;
		line-height: lh(28, 18);
	}
}

@mixin font-body-link-text {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--bold;
	line-height: lh(26, 16);

	@include bp("large") {
		font-size: 18px;
		line-height: lh(28, 18);
	}}

////////////////////////
// Base font variants //
////////////////////////

/* SBN font variants*/

@mixin font-base-20 {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: lh(28, 20);
}

@mixin font-base-20sb {
	@include font-base-20;
	font-weight: $weight--semibold;
}

@mixin font-base-20m {
	@include font-base-20;
	font-weight: $weight--medium;
}

@mixin font-base-20mu {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--medium;
	line-height: lh(28, 20);
	text-transform: uppercase;
}

@mixin font-base-20u {
	@include font-base-20;
	text-transform: uppercase;
}

@mixin font-base-20--tall {
	@include font-base-20;
	text-transform: uppercase;
}

@mixin font-base-18 {
	font-family: $base-font-family;
	font-size: 18px;
	font-weight: $weight--normal;
	line-height: lh(28, 18);
}

@mixin font-base-18u {
	@include font-base-18;
	text-transform: uppercase;
}

@mixin font-base-18sb {
	@include font-base-18;
	font-weight: $weight--semibold;
}

@mixin font-base-18m {
	@include font-base-18;
	font-weight: $weight--medium;
}

@mixin font-base-18m--tall {
	@include font-base-18m;
	text-transform: uppercase;
}

@mixin font-base-18--tall {
	@include font-base-18;
	text-transform: uppercase;
}

@mixin font-base-16 {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--normal;
	line-height: lh(26, 16);
}

@mixin font-base-16u {
	@include font-base-16;
	text-transform: uppercase;
}

@mixin font-base-16sb {
	@include font-base-16;
	font-weight: $weight--semibold;
}

@mixin font-base-16m {
	@include font-base-16;
	font-weight: $weight--medium;
}

@mixin font-base-16mu {
	@include font-base-16m;
	text-transform: uppercase;
}

@mixin font-base-14 {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: lh(24, 14);
}

@mixin font-base-14sb {
	@include font-base-14;
	font-weight: $weight--semibold;
}

@mixin font-base-14m {
	@include font-base-14;
	font-weight: $weight--medium;
}

@mixin font-base-14mu {
	@include font-base-14m;
	text-transform: uppercase;
}

@mixin font-base-14-tall {
	@include font-base-14;
	text-transform: uppercase;
}

@mixin font-base-12 {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--normal;
	line-height: lh(20, 12);
}

@mixin font-base-12-tall {
	@include font-base-12;
	text-transform: uppercase;
}

@mixin font-base-12sbu {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--semibold;
	line-height: lh(20, 12);

	text-transform: uppercase;
}

@mixin font-base-12sb {
	@include font-base-12;
	font-weight: $weight--semibold;
}

@mixin font-base-12m {
	@include font-base-12;
	font-weight: $weight--medium;
}

@mixin font-base-12mu {
	@include font-base-12m;
	text-transform: uppercase;
}

@mixin font-base-10 {
	font-family: $base-font-family;
	font-size: 10px;
	font-weight: $weight--normal;
	line-height: lh(14, 10);
}

@mixin font-base-10-tall {
	@include font-base-10;
	text-transform: uppercase;
}



/* HP font variants -- need to remove later */

@mixin font-base-28b {
	font-family: $base-font-family;
	font-size: 28px;
	font-weight: $weight--bold;
	line-height: lh(36, 28);
}

@mixin font-base-24b {
	font-family: $base-font-family;
	font-size: 24px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-base-20 {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-base-20-tall {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.4;
	letter-spacing: 0.01em;
}

@mixin font-base-20b {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: lh(28, 20);
}

@mixin font-base-18 {
	font-family: $base-font-family;
	font-size: 18px;
	font-weight: $weight--normal;
	line-height: 1.35;
}


@mixin font-base-18b {
	font-family: $base-font-family;
	font-size: 18px;
	font-weight: $weight--bold;
	line-height: 1.35;
}


@mixin font-base-16b {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--bold;
	line-height: 1.5;
	letter-spacing: 0.01em;
}

@mixin font-base-14b {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--bold;
	line-height: 1.5;
}

@mixin font-base-14u {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	text-transform: uppercase;
	line-height: 1.15;
}

@mixin font-base-12 {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-base-12b {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--bold;
	line-height: lh(17, 12);
}

@mixin font-base-10b {
	font-family: $base-font-family;
	font-size: 10px;
	font-weight: $weight--bold;
	line-height: lh(14, 10);
}

///////////////////////////
// Heading font variants //
///////////////////////////

@mixin font-heading-72b {
	font-family: $heading-font-family;
	font-size: 72px;
	font-weight: $weight--bold;
	line-height: lh(78, 72);
}

@mixin font-heading-60b {
	font-family: $heading-font-family;
	font-size: 60px;
	font-weight: $weight--bold;
	line-height: lh(68, 60);
}

@mixin font-heading-52b {
	font-family: $heading-font-family;
	font-size: 52px;
	font-weight: $weight--bold;
	line-height: lh(60, 52);
}

@mixin font-heading-45b {
	font-family: $heading-font-family;
	font-size: 45px;
	font-weight: $weight--bold;
	line-height: lh(52, 45);
}

@mixin font-heading-40b {
	font-family: $heading-font-family;
	font-size: 40px;
	font-weight: $weight--bold;
	line-height: lh(60, 40);
}

@mixin font-heading-36b {
	font-family: $heading-font-family;
	font-size: 36px;
	font-weight: $weight--bold;
	line-height: lh(42, 36);
}

@mixin font-heading-32b {
	font-family: $heading-font-family;
	font-size: 32px;
	font-weight: $weight--bold;
	line-height: lh(42, 32);
}

@mixin font-heading-30b {
	font-family: $heading-font-family;
	font-size: 30px;
	font-weight: $weight--bold;
	line-height: lh(36, 30);
}

@mixin font-heading-28b {
	font-family: $heading-font-family;
	font-size: 28px;
	font-weight: $weight--bold;
	line-height: lh(36, 28);
}

@mixin font-heading-24b {
	font-family: $heading-font-family;
	font-size: 24px;
	font-weight: $weight--bold;
	line-height: lh(32, 24);
}

@mixin font-heading-20 {
	font-family: $heading-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: lh(28, 20);
}

@mixin font-heading-20b {
	font-family: $heading-font-family;
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: lh(28, 20);
}

@mixin font-heading-16b {
	font-family: $heading-font-family;
	font-size: 16px;
	font-weight: $weight--bold;
	line-height: lh(26, 16);
}

/* HP font variants -- need to remove later */

@mixin font-heading-36b {
	font-family: $heading-font-family;
	font-size: 36px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-heading-32 {
	font-family: $heading-font-family;
	font-size: 32px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-heading-32b {
	font-family: $heading-font-family;
	font-size: 32px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-heading-24b {
	font-family: $heading-font-family;
	font-size: 24px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-heading-20 {
	font-family: $heading-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-heading-20-tall {
	font-family: $heading-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.4;
	letter-spacing: 0.01em;
}

@mixin font-heading-18b {
	font-family: $heading-font-family;
	font-size: 18px;
	font-weight: $weight--bold;
	line-height: 1.35;
}

@mixin font-heading-14b {
	font-family: $heading-font-family;
	font-size: 14px;
	font-weight: $weight--bold;
	line-height: 1.5;
}

//////////////////////////////
// Highlight font variants //
//////////////////////////////


/* SBN font variants*/

@mixin font-highlights-100 {
	font-family: $highlights-font-family;
	font-size: 100px;
	font-weight: $weight--normal;
	line-height: lh(74, 100);
}

@mixin font-highlights-68 {
	font-family: $highlights-font-family;
	font-size: 68px;
	font-weight: $weight--normal;
	line-height: lh(74, 100);
}

@mixin font-highlights-36 {
	font-family: $highlights-font-family;
	font-size: 36px;
	font-weight: $weight--normal;
	line-height: lh(42, 36);
}

html {
	font-family: $base-font-family;
	color: $body-color;
}

p {
	margin-top: 0;
}
