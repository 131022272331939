// COMPONENT.CED-LISTING-HERO

$ced-listing-hero-bp: "medium";

.c-ced-listing-hero {
	background-color: $grey--lightest;

	@include bp-below($ced-listing-hero-bp) {
		padding: $bsu 0;
	}
	@include bp($ced-listing-hero-bp) {
		background-image: url("/content/images/interface/bg/white-circles-pattern-quarter-right.svg");
		background-position: bottom right;
		background-repeat: no-repeat;

		padding-top: $lsu;
		// Extra padding for the page filter overlay
		padding-bottom: $lsu + 80px;
	}
}

.c-ced-listing-hero__layout {
	@include layout-wrapper;
}

.c-ced-listing-hero__back {
	display: block;

	@include bp-below($ced-listing-hero-bp) {
		margin-bottom: $ssu;
		@include font-base-16sb;
	}
	@include bp($ced-listing-hero-bp) {
		margin-bottom: $bsu;
		@include font-base-20sb;
	}

	position: relative;
	&::before {
		@include font-icon;
		content: $iconf-arrow-left;

		@include bp-below("extra-large") {
			display: inline-block;
			vertical-align: middle;
			margin-right: $tsu;
		}
		@include bp("extra-large") {
			position: absolute;
			right: calc(100% + #{$tsu});
		}

		color: var(--secondary-a);
	}
}

.c-ced-listing-hero__title {
	display: block;

	@include bp-below($ced-listing-hero-bp) {
		margin-bottom: $ssu;
		@include font-heading-28b;
	}
	@include bp($ced-listing-hero-bp) {
		margin-bottom: $bsu;
		@include bp-below("large") {
			@include font-heading-52b;
		}
	}
	@include bp("large") {
		@include font-heading-72b;
	}
}

.c-ced-listing-hero__text {
	display: block;

	@include bp-below($ced-listing-hero-bp) {
		@include font-base-16;
	}
	@include bp($ced-listing-hero-bp) {
		@include font-base-20;
	}

	> :last-child {
		margin-bottom: 0;
	}
}
