// COMPONENT.HEADER



$header-bp: "large";

$header-height: 65px;
$logo-height: 45px;

:root {
	--header-height: #{$header-height};
	--logo-height: #{$logo-height};

	@include bp("large") {
		$header-height: 120px;
		$logo-height: 86px;

		--header-height: #{$header-height};
		--logo-height: #{$logo-height};
	}
}

.c-header {
	position: fixed;
	z-index: $z-header;
	height: $header-height;
	height: var(--header-height);
	width: 100%;
	top: 0;
	//max-width: $viewport-max-width;
	background-color: var(--primary-a);
	box-shadow: 0 8px 6px -6px rgba($black, 0.2);
	transition: all 0.25s ease-in-out;
	display: flex;
	justify-content: center;
	align-content: center;

	.c-content-type-homepage & {
		background-color: var(--primary-a);
	}

	@include bp("large") {
		$header-height: 120px;
		height: var(--header-height);
		background-color: var(--primary-a);

		.c-content-type-homepage & {
						background-color: transparent;
			box-shadow: none;

			&.is-open {
				transform: none;
				background-color: rgba( $primary-a, 0.92);
			}

			&.is-sticky {
				box-shadow: 0 8px 6px -6px rgba($black, 0.2);

				&.is-open {
					background-color: var(--primary-a);
				}
			}
		}
	}

	&.is-sticky {
		background-color: var(--primary-a);

		@include bp-below("large") {
			.c-content-type-naturedetailpage &,
			.c-content-type-naturedetailsteppage & {
				position: static;
				transform: translateY(100%);
			}
		}

		&.is-up {
			&:not(.is-hamnab-open) {
				transform: translateY(calc(-100% - 6px));
			}

			&.is-open {
				transform: none;
			}

			@include bp-below("large") {
				.c-content-type-naturedetailsteppage & {
					transform: translateY(100%);
				}
			}
		}
	}
}

.c-header__spacer {
	height: $header-height;
	height: var(--header-height);

	.c-content-type-homepage & {
		@include bp("large") {
			height: 0;
		}
	}
}

.c-header__wrapper {
	display: flex;
	@include layout-wrapper;
	align-items: center;
	justify-content: space-between;

	@include bp-below("medium") {
		padding-left: $ssu;
		padding-right: $ssu;
	}
}

.c-header__logo {
	display: inline-block;
	padding: #{$tsu - 3px} 0;
	text-align: center;
	height: $logo-height;
	height: var(--logo-height);

	@include bp("large") {
		padding: #{$ssu - 2px} 0;
	}

	img {
		//width: 150px;
		height: 100%;
		object-fit: cover;
		object-position: center center;
	}

	&:focus {
		@include focus-outline;
	}
}

.c-header__tools {
	/*display: flex;*/
	/*flex: 0;*/
	/*align-items: center;*/
	/*justify-content: flex-end;*/
	order: 3;

	@include bp("medium") {
		//padding-right: $ssu;
	}
}

// Language
.c-header__language {
	display: none;

	@include bp("medium") {
		display: block;
		padding-right: $ssu;
	}
}

.c-header__language-dropdown {
	@include font-base-16;
	display: block;
	padding: 0.65em;
	border: 1px solid $grey;
}


// Mobile buttons
$mobile-button-size: 40px;
$mobile-button-color: $white;
$mobile-button--hover-color: darken($white, 10);
$mobile-button-font-size: 28px;
$mobile-button-padding: calc((#{$mobile-button-size} - #{$mobile-button-font-size}) / 2);

.c-header__mobile-buttons {
	flex: 1 0 auto;
	text-align: right;
	top: -5px;
	display: inline-flex;
	align-items: center;

	@include bp("medium") {
		flex: 0 0 auto;
	}
}

.c-header__mobile-button {
	position: relative;
	width: $mobile-button-size;
	height: $mobile-button-size;
	padding: $mobile-button-padding 0;
	border: 0;
	background: none;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

// Nav Toggle
.c-header__nav-toggle {
	@include button-reset;
	top: -5px;
	z-index: 3;

	b {
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, border-color 0.3s ease-in-out;
		position: absolute;
		left: $mobile-button-padding;
		display: block;
		width: $mobile-button-font-size;
		height: 0;
		border-top: 2px solid $mobile-button-color;

		&:nth-child(1) {
			top: $mobile-button-padding;
		}

		&:nth-child(2) {
			top: calc(#{$mobile-button-padding} + 8px);
		}

		&:nth-child(3) {
			top: calc(#{$mobile-button-padding} + 16px);
		}
	}

	&[aria-expanded="true"] {

		b {
			&:nth-child(1) {
				transform: translateY(8px) rotate(45deg);
			}

			&:nth-child(2) {
				opacity: 0;
			}

			&:nth-child(3) {
				transform: translateY(-8px) rotate(-45deg);
			}
		}
	}

	&:hover,
	&[aria-expanded="true"] {
		b {
			border-color: $mobile-button--hover-color;
		}
	}

	&:hover {
		color: var(--secondary-a);

		b {
			border-color: var(--secondary-a);
		}
	}
}

.c-header__mobile-button-text {
	@include font-base-10-tall;
	left: $mobile-button-padding;
	position: absolute;
	bottom: -3px;
}

// Search Toggle
.c-header__search-toggle {
	@include button-reset;
	font-size: ( $mobile-button-font-size - 5px);
	color: $mobile-button-color;

	&:hover {
		color: var(--secondary-a);
	}

	&[aria-expanded="true"] .iconf-search {
		&:before {
			content: $iconf-close;
		}
	}

	.iconf-search {
		position: absolute;
		left: $mobile-button-padding;
		top: $mobile-button-padding;
	}
}

.c-header__search {
	display: none;
	position: absolute;
	background: $grey--lightest;
	top: $header-height;
	top: var(--header-height);
	left: 0;
	width: 100%;
	height: calc(100vh - #{$header-height});
	height: calc(100vh - var(--header-height));
	box-shadow: 0 8px 6px -6px rgba(0,0,0,0.2);

	@include bp($header-bp) {
		position: absolute;
		left: 0;
		top: $header-height;
		top: var(--header-height);
		height: auto;
	}
}

.c-header__search-wrapper {
	width: 65%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding-bottom: 20px;

	@include bp($header-bp) {
		width: 700px;
		position: static;
		padding: $lsu 0;
		margin: 0 auto;
		top: auto;
		left: auto;
		transform: none;
	}
}

.c-header__search-field {
	position: relative;
}

.c-header__search-input {
	@include font-base-16;
	@include placeholder-text($grey);
	display: block;
	padding: $tsu $ssu;
	width: 100%;
	margin: 0 auto;
	border: none;
	border: 1px solid var(--primary-a);
	border-radius: 50px;
	background: transparent;

	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}

	&:focus-visible {
		outline-offset: 0;
		outline-color: var(--secondary-a);
	}
}

.c-header__search-btn {
	@include button-reset;
	position: absolute;
	padding: 0;
	width: 50px;
	height: 50px;
	right: 0;
	top: 0;
	font-size: 26px;
	line-height: 1;
	color: $brand;

	&::before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@include link-transition;

	&:hover,
	&:focus {
		color: darken($brand, 10%);
	}
}

.c-header__log-in {
	@include bp-below($header-bp) {
		display: none;
	}
}

.c-header__cta {
	@include button("small");
	background-color: transparent;
	color: var(--primary-b);
	border: 1px solid var(--primary-b);
	border-radius: 2px;
	padding: 5px $ssu;
	margin: calc($tsu / 2);

	@include bp("medium") {
		@include button("medium");
		padding: 8px calc($ssu * 1.5);
		border: 1px solid var(--primary-b);
		border-radius: 2px;
	}

	@include bp("extra-large") {
		margin: 0 $ssu;
		border-radius: 2px;
	}

	&:hover,
	&:focus {
		border-color: var(--primary-a);
		background-color: var(--secondary-a);
	}
}
