// CONTROL.DOCUMENT-DOWNLOAD

$document-download-bp: "large";

.c-document-download {
	@include control("grey");
}

.c-document-download__layout {
	@include layout-wrapper;
}

.c-document-download__layout--narrow {
	@include layout-wrapper--narrow;
}

.c-document-download__split {
	display: flex;
	flex-wrap: wrap;

	margin-left: -$lsu;
	margin-top: -$lsu;
}

.c-document-download__body {
	padding-left: $lsu;
	padding-top: $lsu;

	width: 100%;
	@include bp($document-download-bp) {
		&:not(:last-child) {
			width: 50%;
		}
	}
}

.c-document-download__heading {
	@include control__heading;
}

.c-document-download__text {
	display: block;

	@include font-base-16;
}

.c-document-download__form {
	.umbraco-forms-form,
	.umbraco-forms-submitmessage {
		display: block;
		padding-top: $bsu;
	}
	.c-form__submitted {
		@include font-base-18;
	}
}

.c-document-download__download {
	@include button("large");
	display: none;

	.c-form__submitted ~ &,
	#umbraco-forms-form-submitted  ~ & {
		display: inline-block;
	}

	margin-top: $bsu;
}

.c-document-download__media {
	padding-left: $lsu;
	padding-top: $lsu;

	@include bp-below($document-download-bp) {
		width: 100%;
	}
	@include bp($document-download-bp) {
		width: 50%;
	}
}

.c-document-download__figure {
	@include figure;
}

.c-document-download__image {
	background-color: transparent !important;
}
