﻿// CONTROL.EXPAND-COLLAPSE

.c-expand-collapse {
	@include control;

	+ .c-expand-collapse {
		
	}
}

.c-expand-collapse__layout {
	@include layout-wrapper--narrow;
}

.c-expand-collapse__heading {
	@include control__heading;
}

.c-expand-collapse__initial-content {
	@include body-text;
}

.c-expand-collapse__item-header {
	@include link-transition;
	position: relative;
	padding: ($ssu * 1.5) $bsu;
	padding-right: $bsu * 3;
	background-color: $white;
	cursor: pointer;

	&:not(:focus-visible) {
		outline: none;
	}

	&:hover,
	&:focus {
		color: $brand;
	}
}

.c-expand-collapse__item-btn {
	@include button;
	@include button--text;
	@include font-base-16sb;

	position: relative;
	padding-left: 2em;
	display: flex;
	margin: $ssu 0 0;
	justify-content: center;
	align-items: center;
	position: relative;
	padding-left: 2em;

	[class*="iconf"] {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
	}
}

.c-expand-collapse__item-btn-icon {
	font-size: 17px;

	.c-expand-collapse__item.is-expanded &,
	.c-expand-collapse__item[aria-expanded="true"] & {
		&::before {
			content: $iconf-minus;
		}
	}
}

.c-expand-collapse__item-btn-text {}
.c-expand-collapse__item-btn--open {
	.c-expand-collapse__item[aria-expanded="true"] & {
		display: none;
	}
}

.c-expand-collapse__item-btn--close {
	.c-expand-collapse__item:not([aria-expanded="true"]) & {
		display: none;
	}
}

.c-expand-collapse__item-body {
	@include body-text;

	padding: $ssu 0;

	.js & {
		display: none;
	}

	.c-expand-collapse__item.is-expanded &,
	.c-expand-collapse__item[aria-expanded="true"] & {
		display: block;
	}
}
