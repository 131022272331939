// CONTROL.SOLUTION-PODS

.c-solution-pods {
	@include control("grey");
}

.c-solution-pods__layout {
	@include layout-wrapper;
}

.c-solution-pods__heading {
	@include control__heading;
}

.c-solution-pods__list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	margin-left: -$tsu;
	margin-top: -$bsu;

	@supports (display: grid) {
		display: grid;
		margin: 0;
		grid-row-gap: $bsu;
		grid-column-gap: $tsu;
		grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
	}
}

.c-solution-pods__list-item {
	padding-left: $tsu;
	padding-top: $bsu;
	width: 100%;

	@include bp("medium") {
		width: 50%;
	}

	@include bp("large") {
		width: 33.33%;

		.l-page--has-sidebar .l-page__main-controls & {
			width: 50%;
		}
	}

	@include bp("extra-large") {
		width: 33.33%;
	}

	@supports (display: grid) {
		&, .l-page--has-sidebar .l-page__main-controls & {
			width: 100%;
			padding: 0;
		}
	}
}
