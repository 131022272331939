﻿// CONTROL.IMAGE

.c-image {
	@include control;
}

.c-image__layout {
	@include layout-wrapper;
}

.c-image__heading {
	@include control__heading;
}

.c-image__figure {
	@include figure;
}

.c-image__figcaption {
	@include control__figcaption;
}

.c-image__figure-link {
	position: relative;
}

.c-image__figure-linkicon {
	@include button("small");
	position: absolute;
	z-index: 1;
	bottom: 0;
	right: 0;

	@include bp("medium") {
		bottom: $ssu;
		right: $ssu;
	}

	@include bp("large") {
		bottom: $bsu;
		right: $bsu;
	}
}