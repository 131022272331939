﻿// PLUGINS.SS360

// Wrapper to override SS360 defaults
.p-ss360-results {
	// Shared styles
	.ss360-layer {
		padding: 0;
		margin-bottom: 20px;

		@include bp('large') {
			margin-bottom: 40px;
		}
	}

	.ss360-list {
		@include list-reset;
		margin-bottom: 20px;

		@include bp('large') {
			margin-bottom: 40px;
		}
	}

	.ss360-suggests {
		width: 100%;
		margin: 0;
		box-shadow: none;
	}

	.ss360-more-results {
		margin: 0 auto;
	}
	// General Result
	.p-ss360__result {
		padding-bottom: 16px;
		border-bottom: 1px solid $base-ui-color;
		margin-bottom: 20px;
	}

	.p-ss360__result-title {
		@include font-heading-18b;
	}

	.p-ss360__result-abstract {
		@include font-base-14;
		width: 100%;
		margin: 10px 0 0;
	}
	// General Result - with image
	.p-ss360__result--image {
		display: flex;
		flex-wrap: wrap;

		@include bp('small') {
			display: grid;
			grid-template-columns: 180px auto;
			grid-template-rows: auto 1fr;
		}

		.p-ss360__result-figure {
			width: 33%;

			@include bp('small') {
				width: 180px;
				grid-row-start: 1;
				grid-row-end: 3;
			}
		}

		.p-ss360__result-image {
			padding-bottom: 67%;
			background-color: transparent;
		}

		.p-ss360__result-title {
			align-self: center;
			width: 67%;
			padding-left: 20px;

			@include bp('small') {
				grid-column-start: 2;
				grid-column-end: 3;
			}
		}

		.p-ss360__result-abstract {

			@include bp('small') {
				grid-column-start: 2;
				grid-column-end: 3;
				padding-left: 20px;
			}
		}
	}
}

// CED Result
.p-ss360-results--ced {

	.ss360-list {
		display: flex;
		flex-wrap: wrap;
		margin-left: -$ssu;
		margin-top: -$bsu;
	}

	.ss360-suggests {
		padding-left: $ssu;
		padding-top: $bsu;

		@include bp("medium") {
			width: 50%;
		}

		@include bp("large") {
			width: 33.33%;
		}
	}

	.p-ss360__ced-image {
		padding-bottom: 100%;
	}

	.p-ss360__ced-text {
		padding-top: 20px;
		margin-top: 0;
	}

	.p-ss360__ced-figure + .p-ss360__ced-text {
		padding-top: 35%;
		margin-top: -35%;
	}

	@supports (display: grid) {
		.ss360-list {
			display: grid;
			margin: 0;
			grid-gap: $bsu $ssu;
			grid-template-columns: 100%;

			@include bp("medium") {
				grid-template-columns: repeat(2, minmax(0, 1fr));
			}

			@include bp("large") {
				grid-template-columns: repeat(3, minmax(0, 1fr));
			}
		}

		.ss360-suggests {
			padding: 0;
			width: 100%;
		}
	}
}
