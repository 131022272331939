/* TOOLS.MIXINS */
@use "sass:math";

// Font Icons
@mixin font-icon {
	// use !important to prevent issues with browser extensions that change fonts
	font-family: '#{$icomoon-font-family}' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	// Better Font Rendering
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// Breakpoint mixin
// Uses $breakpoints variable set in _config.scss file
@mixin bp($size) {

	@if (type-of($size) == "number") {
		@media (min-width: $size) {
			@content;
		}
	} @else if (type-of($size) == "string") {
		@each $breakpoint in $breakpoints {
			// Get the name of the breakpoint.
			$alias: nth($breakpoint, 1);

			@if ($size == $alias) {
				$start: bp-config-value($breakpoint, "start");

				@media (min-width: $start) {
					@content;
				}
			}
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from '#{$size}'. Please make sure it is defined in '$breakpoints' map.";
	}
}

@mixin bp-below($size) {

	@if (type-of($size) == "number") {
		@media (max-width: #{em($size - 1px)}) {
			@content;
		}
	} @else if (type-of($size) == "string") {
		@each $breakpoint in $breakpoints {
			// Get the name of the breakpoint.
			$alias: nth($breakpoint, 1);

			@if ($size == $alias) {
				$start: bp-config-value($breakpoint, "start");

				@media (max-width: #{$start - 1px}) {
					@content;
				}
			}
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from '#{$size}'. Please make sure it is defined in '$breakpoints' map.";
	}
}

// Positioning
@mixin abs-center {
	position: absolute;
	right: 50%;
	top: 50%;
	transform: translate(50%, -50%);
}

@mixin abs-center-h {
	position: absolute;
	right: 50%;
	transform: translateX(50%);
}

@mixin abs-center-v {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}


// Layout
@mixin layout-split($side, $split, $width: $layout-wrapper-max-width, $gutter: $bsu) {
	// $side should be either left or right
	// $split should be a percentage

	// Example use:
	// @include layout-split(left, 40%);

	// To be used elements that need to extend to the edge of the page,
	// so it can't be wrapped in a layout-wrapper, but with content that
	// needs to align with indented content as though it were in a wrapper.

	// Because it uses % padding, it's important that any element using this
	// sits within a container with a specified width. That width should be
	// the same as $split as a proportion of the screen width

	$counter-split: 100% - $split;
	$ratio: math.div($counter-split , $split);

	$growthRate: calc((((#{$ratio} - 1) / 2) + 1) * 100%);
	$normaliser: calc((#{$width} / 2) - #{$gutter});

	@include bp($width) {
		padding-#{$side}: calc(#{$growthRate} - #{$normaliser});
	}
}

/**
 * The "RAM" pattern is Repeat Auto Minmax, which allows items in a grid
 * to reflow automatically with the goal of putting as many items as
 * possible on a row while also never going below a minimum size
 */
@mixin grid-ram($size) {
	grid-template-columns: repeat(auto-fit, minmax($size, 1fr));
}

// Transition
@mixin link-transition($prop: all, $speed: 0.25s) {
	transition: $prop $speed ease-in-out;

	@media (prefers-reduced-motion) {
		transition: none;
	}
}

@mixin scroll-appear {
	@media screen and (prefers-reduced-motion: no-preference) {
		.js &:not([data-scroll-appear="true"]) {
			@content;
		}
	}
}

// Clearfix
@mixin clearfix {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin remove-clearfix {
	&::after {
		display: none;
		clear: none;
	}
}

// Pseudo Element
@mixin pseudo-element {
	content: '';
	display: block;
	position: absolute;
}

// Placeholder Text
@mixin placeholder-text ($color) {
	&::-webkit-input-placeholder {
		color: $color;
	}
	&:-moz-placeholder {
		color: $color;
	}
	&:-ms-input-placeholder {
		color: $color;
	}
}

// Mixin to add square dimensions to an element i.e. the same width and height
// Example: Create a square 40em high and wide
// @include tb_square ( 40em );
@mixin square($size) {
	width: $size;
	height: $size;
}

// Mixin to add circle dimensions to an element i.e. adds square dimensions and border-radius of (dimensions / 2)
// Example: Create a circle 40em high and wide
// @include tb_square ( 40em );
@mixin circle($size) {
	@include square($size);
	border-radius: calc(#{$size} / 2);
}

// UI Border mixin
$ui-border: 1px solid $base-ui-color;

@mixin ui-border($side) {
	@if ($side == 'all') {
		border: $ui-border;
	} @else if ($side == 'top') {
		border-top: $ui-border;
	} @else if ($side == 'right') {
		border-right: $ui-border;
	} @else if ($side == 'bottom') {
		border-bottom: $ui-border;
	} @else if ($side == 'left') {
		border-left: $ui-border;
	}
}

// Resets
@mixin button-reset {
	// Used to reset styles on <button> elementss
	// This can be used for interactive elements that aren't links
	background: transparent;
	border: none;
	border-radius: 0;
	padding: 0;
	cursor: pointer;
}

@mixin link-plain {
	@include link-transition;
	color: inherit;
}

@mixin link-brand {
	color: var(--link);
	text-decoration-line: underline;
	text-decoration-color: var(--link--hover);

	&:hover,
	&:focus {
		color: var(--link--hover);
	}
}

// Forms
@mixin form-input {
	// iOS zooms in unless font size in inputs is at least 16px
	@include font-base-16;

	display: block;
	width: 100%;
	// On Chrome, setting line-height doesn't work correctly on select elements
	min-height: 50px;

	// 11px top and bottom padding makes them 48px tall, same as buttons
	padding: 11px 0.65em;

	border: 1px solid $grey;
	box-shadow: inset 0 1px 3px 0 rgba($black, 0.2);

	&.input-validation-error,
	&.c-form__input.has-error {
		border-color: $error;
		background-color: $error--light;
		margin-bottom: calc($tsu / 2);
		box-shadow: none;
	}

	&:focus-visible {
		box-shadow: none;
		@supports (border-color: var(--supporting-1-a)) {
			outline: none;
			border-color: var(--supporting-1-a);
		}
	}
}

$outline-default: #4d90fe;
@mixin focus-outline($color: $outline-default) {
	// Replicate Chrome's default focus style
	outline-color: $color;
	@if ($color == $outline-default) {
		@supports (outline-color: -webkit-focus-ring-color) {
			outline-color: -webkit-focus-ring-color;
		}
	}

	// IE11 and Edge don't support outline-style: auto
	outline-style: solid;
	outline-width: 2px;
	@supports(outline-style: auto) {
		outline-style: auto;
	}
}
