/* OBJECT.LAYOUT */

@mixin layout-wrapper--flush($width: $layout-wrapper-max-width) {
	max-width: $width;
	margin: 0 auto;
	flex-grow: 1;
}

@mixin layout-wrapper($width: $layout-wrapper-max-width) {
	@include layout-wrapper--flush($width);
	padding: 0 $bsu;
	padding: 0 var(--layout-gutter-right) 0 var(--layout-gutter-left);

	> * {
		--layout-gutter-left: 0;
		--layout-gutter-right: 0;
		--layout-narrow-shift: 0;
	}
}

@mixin layout-wrapper--narrow {
	@include layout-wrapper($layout-wrapper-narrow-width);
	.l-page:not(.l-page--has-sidebar) & {
		@include bp($layout-wrapper-narrow-width) {
			@include bp-below($layout-wrapper-max-width) {
				// Narrow wrappers are full width, but full width wrappers are not
				transform: translateX(calc(var(--layout-narrow-shift) * calc(calc(-0.5 * var(--page-width)) + (#{$layout-wrapper-narrow-width} / 2))));
				> * {
					--layout-narrow-shift: 0;
				}
			}
		}
		@include bp($layout-wrapper-max-width) {
			// The page is wide enough that we can treat layout wrappers' max-width rules as width rules
			transform: translateX(calc(var(--layout-narrow-shift) * (($layout-wrapper-max-width - $layout-wrapper-narrow-width) / 2 * -1)));
			> * {
				--layout-narrow-shift: 0;
			}
		}
	}
	.l-page:not(.l-page--has-sidebar) .c-two-column__column & {
		transform: none;
	}
}

.o-layout-wrapper {
	@include layout-wrapper;
}

.o-layout-wrapper--narrow {
	max-width: $layout-wrapper-narrow-width;
}

.o-layout-page {
	position: relative;
	z-index: $z-page;
	padding-top: $bsu * 3;
}

.o-layout-content--has-aside {
	@include bp("large") {
		display: flex;
	}
}

.o-layout-main {
	display: block;

	.o-layout-content--has-aside & {
		@include bp("large") {
			width: calc(100% - #{$layout-aside-width + $layout-aside-gutter-width});
		}
	}
}

.o-layout-aside {
	margin-top: $lsu;

	@include bp("large") {
		width: $layout-aside-width + $layout-aside-gutter-width;
		padding-left: $layout-aside-gutter-width;
		margin-top: 0;

		&.is-left {
			padding-left: 0;
			padding-right: $layout-aside-gutter-width;
		}
	}
}

.o-layout-header {
	margin-bottom: $hsu;
	text-align: center;

	> *:last-child {
		margin-bottom: 0;
	}
}

.o-layout-header--has-cta {
	// See _component.cta-intro.scss
	text-align: left;
	margin-bottom: $bsu;
}

.o-layout-header__subheading {
	display: block;
	margin-bottom: $ssu;
	@include font-base-18b;
}

.o-layout-header__heading {
	display: block;
	margin-bottom: 0.3em;
}

.o-layout-header__heading,
.o-layout-header__heading-text {
	@include font-heading-30b;
	margin-bottom: $bsu;

	@include bp("large") {
		@include font-heading-40b;
	}

	@include bp("extra-large") {
		@include font-heading-52b;
	}
}

.o-layout-header__heading-text {
	display: inline-block;
	vertical-align: middle;

}

.o-layout-header__highlight {
	display: inline-block;
	vertical-align: middle;

	margin-left: $bsu;

	@include font-base-16;
	padding: $tsu $ssu;
}

.o-layout-header__lede {
	@include font-base-20;
}

.o-layout-header__lede--full {
	width: auto;
}

.o-layout-header__date {
	@include font-base-12;
	margin-bottom: 2em;
}