// COMPONENT.CED-SOLUTION-HERO

$ced-solution-hero-bp: "large";

.c-ced-solution-hero {
	position: relative;
	margin-bottom: 0;

	@include bp("medium") {
		margin-bottom: $bsu;
	}

	.c-hero__primary {
		display: flex;
		align-items: stretch;

		background-color: $grey--lightest;
		background-image: url("/content/images/interface/bg/white-circles-pattern-quarter-left.svg");
		background-position: bottom left;
		background-repeat: no-repeat;

		margin-bottom: 0;

		@include bp-below($ced-solution-hero-bp) {
			background-size: 200px;
		}

		.c-hero__body {
			align-items: flex-start;
			margin-bottom: 0;

			@include bp-below($ced-solution-hero-bp) {
				padding: 0;
			}
			@include bp($ced-solution-hero-bp) {
				padding-right: $bsu;
				padding-top: $lsu;
				padding-bottom: $bsu;
			}
		}

		.c-hero__figure {
			@include figure;
			width: 100%;

			@include bp("large") {
				order: 1;
				width: 50%;
			}

			.c-hero__image {
				@include bp($ced-solution-hero-bp) {
					height: 100%;
					min-height: 650px;
				}
				@include bp("extra-large") {
					min-height: 740px;
				}
			}
		}
	}
}

.c-ced-solution-hero__back {
	display: block;

	@include bp-below($ced-solution-hero-bp) {
		@include font-base-16sb;
		margin-bottom: $bsu;
	}
	@include bp($ced-solution-hero-bp) {
		@include font-base-20sb;
		margin-bottom: $lsu;
	}

	&::before {
		@include font-icon;
		content: $iconf-arrow-left;

		display: inline-block;
		vertical-align: center;
		margin-right: $tsu;

		color: var(--secondary-a);
	}

	@include link-transition;

	@include bp($ced-solution-hero-bp) {
		transform: translateX(calc(-1em - #{$tsu}));
	}
}

.c-ced-solution-hero__title {
	display: block;
	margin-bottom: $bsu;

	@include bp-below("large") {
		@include font-heading-28b;
	}
	@include bp("large") {
		@include font-heading-40b;
	}
	@include bp("extra-large") {
		@include font-heading-52b;
	}
}

.c-ced-solution-hero__introduction {
	@include bp-below($ced-solution-hero-bp) {
		@include font-base-18;
	}
	@include bp($ced-solution-hero-bp) {
		@include font-base-20;
	}
}
