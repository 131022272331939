// OBJECT.CONTROL

$control-bp: "large";

// See _config.scss for some specific control spacing rules

@mixin control($colour-top: "white", $colour-bottom: $colour-top) {
	margin-top: $lsu;
	margin-bottom: $lsu;
	margin-top: var(--control-margin-top);
	margin-bottom: var(--control-margin-bottom);
	padding-top: 0;
	padding-bottom: 0;
	padding-top: var(--control-padding-top);
	padding-bottom: var(--control-padding-bottom);

	.l-page__sidebar & {
		&:first-child {
			--control-margin-top: 0;
		}
	}

	.l-page__sidebar-sponsor & {
		&:first-child {
			@include bp-below("large") {
				--control-margin-top: 0;
			}
		}

		&:last-child {
			@include bp-below("large") {
				--control-margin-bottom: 0;
			}
		}
	}

	@if ($colour-top == "white") {
		.l-page__main-controls > & {
			&:first-child {
				margin-top: calc(var(--control-margin-top) * 0.75);
			}
		}
	}

	@if ($colour-top == "grey") {
		@if ($colour-bottom == "grey") {
			background-color: $grey--lightest;
			--control-bg-offset: #{$white};
		}

		&:first-child {
			--control-margin-top: 0;
		}

		--control-padding-top: var(--control-gap-top);
		.l-page__main-controls > & {
			&:first-child {
				--control-padding-top: calc(var(--control-gap-top) * 0.75);
			}
		}

		.l-page__secondary-controls & {
			&:first-child {
				--control-margin-top: var(--control-gap-top);
			}
		}

		// If the last control was also grey, or it was dark grey, or it should be flush, remove the margin between them
		--control-margin-top: calc(var(--control-gap-top) * (1 - var(--last-control-was-dark-grey)) * (1 - var(--last-control-was-grey)) * (1 - var(--last-control-was-flush)));
		// If the last control was also grey, remove the top padding to emulate collapsing margins
		--control-padding-top: calc(var(--control-gap-top) * calc(1 - var(--last-control-was-grey)));

		// If it's next to a sidebar, don't change the background
		.l-page--has-sidebar .l-page__main-controls & {
			--control-padding-top: 0;
			--control-margin-top: var(--control-gap-top);
		}
	}
	@if ($colour-bottom == "grey") {
		--control-padding-bottom: var(--control-gap-bottom);
		--control-margin-bottom: 0;

		+ * {
			--last-control-was-grey: 1;
		}

		// If it's next to a sidebar, don't change the background
		.l-page--has-sidebar .l-page__main-controls & {
			background-color: transparent;
			--control-bg-offset: #{$grey--lightest};
			--control-padding-bottom: 0;
			--control-margin-bottom: var(--control-gap-bottom);

			+ * {
				--last-control-was-dark-grey: 0;
			}
		}
	}
	@else if ($colour-top == "dark grey") {
		@if ($colour-bottom == "dark grey") {
			background-color: $grey--light;
			--control-bg-offset: #{$white};
		}

		&:first-child {
			--control-margin-top: 0;
		}

		--control-padding-top: var(--control-gap-top);
		.l-page__main-controls > & {
			&:first-child {
				--control-padding-top: calc(var(--control-gap-top) * 0.75);
			}
		}

		.l-page__secondary-controls & {
			&:first-child {
				--control-margin-top: var(--control-gap-top);
			}
		}
		// If the last control was also grey, or it was dark grey, or it should be flush, remove the margin between them
		--control-margin-top: calc(var(--control-gap-top) * (1 - var(--last-control-was-dark-grey)) * (1 - var(--last-control-was-grey)) * (1 - var(--last-control-was-flush)));
		// If the last control was also dark grey, remove the top padding to emulate collapsing margins
		--control-padding-top: calc(var(--control-gap-top) * calc(1 - var(--last-control-was-dark-grey)));

		// If it's next to a sidebar, don't change the background
		.l-page--has-sidebar .l-page__main-controls & {
			--control-padding-top: 0;
			--control-margin-top: var(--control-gap-top);

			+ * {
				--last-control-was-dark-grey: 0;
			}
		}
	}
	@if ($colour-bottom == "dark grey") {
		--control-padding-bottom: var(--control-gap-bottom);
		--control-margin-bottom: 0;

		+ * {
			--last-control-was-dark-grey: 1;
		}

		// If it's next to a sidebar, don't change the background
		.l-page--has-sidebar .l-page__main-controls & {
			background-color: transparent;
			--control-bg-offset: #{$grey--lightest};
			--control-padding-bottom: 0;
			--control-margin-bottom: var(--control-gap-bottom);

			+ * {
				--last-control-was-dark-grey: 0;
			}
		}
	}
}

@mixin control__heading {
	@include font-heading-24b;
	margin-bottom: $bsu;

	@include bp($control-bp) {
		@include font-heading-36b;
	}
}

@mixin control__heading--small {
	@include font-heading-20b;
	margin-bottom: $bsu;

	@include bp($control-bp) {
		@include font-heading-28b;
	}
}

@mixin control__figcaption {
	@include ui-border("bottom");
	@include font-base-16;
	padding: $bsu 0;
}
