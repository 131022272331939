﻿// CONTROL.IMAGE-GRID

.c-image-grid {
	@include control;
}

.c-image-grid__layout {
	@include layout-wrapper;
}

.c-image-grid__heading {
	@include control__heading;
}

.c-image-grid__list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	margin-left: -$bsu;
	margin-top: -$bsu;

	@supports (display: grid) {
		display: grid;

		.c-image-grid--large & {
			grid-template-columns: repeat(2, 1fr);
			grid-gap: $lsu;
		}

		&, .c-image-grid--medium & {
			grid-template-columns: repeat(4, 1fr);
			grid-gap: $bsu;
		}

		.c-image-grid--small & {
			grid-template-columns: repeat(6, 1fr);
			grid-gap: 0 $bsu;
		}

		margin: 0;
	}

	.c-image-grid__item {
		display: flex;
		flex-direction: column;
		padding-left: $bsu;
		padding-top: $bsu;

		.c-image-grid--large & {
			height: 400px;
		}

		&, .c-image-grid--medium & {
			height: 250px;
		}

		.c-image-grid--small & {
			height: 150px;
		}

		@supports (display: grid) {
			padding: 0;

			&,
			.c-image-grid--large &,
			.c-image-grid--medium &,
			.c-image-grid--small &,
			.c-image-grid--tiny & {
				width: 100%;
			}
		}

		position: relative;

		.c-image-grid__item-wrapper {
			height: calc(100% - 40px);
			position: relative;
		}

		a {
			border: 1px solid $white;
			@include link-transition;

			.c-image-grid__item-link {
				opacity: 0;
				@include link-transition;
			}
		}

		&:hover {
			a {
				border: 1px solid $green;
				@include link-transition;

				.c-image-grid__item-link {
					background: $green;
					opacity: 1;
					@include link-transition;
				}

				+ .c-image-grid__item-caption {
					color: $green;
					@include link-transition;
				}
			}
		}
	}
}

.c-image-grid__item-figure {
	flex-grow: 1;
	height: 100%;
	@include figure;
	overflow: hidden;
}

a.c-image-grid__item-figure {
	&:hover,
	&:focus-visible {
		.c-image-grid__item-image {
			.c-image-grid--cover & {
				transform: scale(1.1);
			}

			.c-image-grid--contain & {
				// Scale up less because these may
				// be logos that shouldn't get cut off
				transform: scale(1.05);
			}
		}
	}
}

.c-image-grid__item-image {
	width: 100%;
	height: 100%;
	padding: 0 !important;
	background-color: white !important;
	display: block;

	@include link-transition(transform);

	img {
		position: relative;
		object-fit: contain;
	}
}

.c-image-grid__item-link {
	position: absolute;
	bottom: 0;
	right: 0;

	@include button("small", $icon: true);

	&::before {
		@include font-icon;
		content: $iconf-external;
	}
}

.c-image-grid__item-caption {
	height: 40px;
	padding-top: 10px;
}

/*====----  Container Queries  ----====*/

@include bp-below("large") {

	.c-image-grid__list {
		grid-gap: $bsu;

		@supports (display: grid) {
			display: grid;

			.c-image-grid--large & {
				grid-template-columns: repeat(2, 1fr);
				grid-gap: $bsu;
			}

			&, .c-image-grid--medium & {
				grid-template-columns: repeat(4, 1fr);
				grid-gap: $bsu;
			}

			.c-image-grid--small & {
				grid-template-columns: repeat(4, 1fr);
				grid-gap: 0 $bsu;
			}
		}

		.c-image-grid__item {

			.c-image-grid--large & {
				height: 300px;
			}

			&, .c-image-grid--medium &,
			.c-image-grid--small & {
				height: 200px;
			}
		}
	}
}

@include bp-below("medium") {

	.c-image-grid__list {

		@supports (display: grid) {
			display: grid;

			.c-image-grid--large & {
				grid-template-columns: repeat(2, 1fr);
				grid-gap: $bsu;
			}

			&, .c-image-grid--medium & {
				grid-template-columns: repeat(2, 1fr);
				grid-gap: $bsu;
			}

			.c-image-grid--small & {
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 0 $bsu;
			}
		}

		.c-image-grid__item {

			.c-image-grid--large &,
			&, .c-image-grid--medium &,
			.c-image-grid--small & {
				height: 200px;
			}
		}
	}
}
