// COMPONENT.FEATURED-EVENT

.c-featured-event {
	// @include control;
	position: relative;
	margin-bottom: -#{$lsu};
	display: none;

	@include bp("large") {
		display: block;
		max-height: 860px;
		overflow: hidden;
	}
}

.c-featured-event__media {
	width: calc( 50% - #{$lsu});
	//position: relative;
	z-index: 1;

	@include bp-below(1440px) {
		width: 50%;
	}
}

.c-featured-event__image {}

.c-featured-event__body {
	width: calc( 50% + #{$lsu});
	height: calc( 100% - #{$lsu});
	position: absolute;
	z-index: 0;
	background-color: $grey--lightest;
	margin-left: calc( 50% - #{$lsu});
	justify-content: center;
	align-items: center;
	display: flex;
	top: 0;
	padding-left: $lsu;
	padding-right: $lsu;
	padding-top: $lsu;
	padding-bottom: $lsu;
	background-image: url("/content/images/interface/bg/white-circles-pattern-quarter-right.svg");
	background-position: bottom right;
	background-repeat: no-repeat;

	@include bp-below(1440px) {
		width: 50%;
		margin-left: 50%;
		padding-left: $bsu;
		padding-right: $bsu;
	}

	@include bp-below("extra-large") {
		padding-left: $bsu;
		padding-right: $bsu;
		background-size: 150px;
	}
}

.c-featured-event__body-content {

	@include bp-below("large") {
		padding-right: $lsu;
	}

	@include layout-split(right, 50%);
}

.c-featured-event__heading-wrapper {
	position: relative;
	display: flex;
	padding-right: ($ssu * 5);
}

.c-featured-event__heading {
	@include font-heading-30b;
	margin-bottom: $bsu;

	@include bp("large") {
		@include font-heading-40b;
	}

	@include bp("extra-large") {
		@include font-heading-52b;
	}
}

.c-featured-event__abstract {
	@include font-base-20;
	line-height: lh(32, 20);
	margin-bottom: $bsu;
	padding-right: ($ssu * 5);

	@include bp-below("extra-large") {
		display: none;
	}
}

.c-featured-event__link {
	@include button("medium");
	border: 1px solid var(--primary-a);

	//position: relative;
}


.c-featured-event__meta {
}

.c-featured-event__date {
}

.c-featured-event__category {
	@include font-base-16u;
	display: inline-block;
	margin-bottom: $ssu;

	@include bp("extra-large") {
		@include font-base-18--tall;
	}
}


