// COMPONENT.NATURE-DETAILS

//pagination

l-page__pagination-controls {

}
.c-nature-details__pagination-wrapper {
	@include control;
	@include layout-wrapper;
	display: flex;
	margin-top: var(--control-margin-top);
	margin-bottom: var(--control-margin-bottom);
	padding-top: 0;
	align-items: stretch;
	position: relative;
	z-index: 1;

	@supports (display: grid) {
		grid-gap: calc($tsu / 2);

		@include bp("large") {
			display: grid;
			//grid-template-columns: 100%;
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
	}

	@include bp("large") {
		margin-top: calc(-1 * var(--control-margin-top));
	}


	@include bp-below("large") {
		padding: 0;
	}
}

.c-nature-details__pagination {
	flex: 1;
	position: relative;
	background-color: $grey--light;

	&:before {
		content: "";
		z-index: 1;
		width: 2px;
		height: 100%;
		background: var(--secondary-a);
		position: absolute;
		top: 0;
		transition: all ease-in 0.3s;
	}

	&:hover {
		&:before {
			width: 100%;
			transition: all ease-in 0.3s;
		}
	}
}

.c-nature-details__pagination__prev {
	grid-column-start: 1;

	&:before {
		left: 0;
	}
}

.c-nature-details__pagination__next {
	grid-column-start: 2;

	&:before {
		right: 0;
	}
}


.c-nature-details__pagination-link {
	@include font-heading-16b;
	display: flex;
	flex-direction: column;
	height: 100%;
	align-content: center;
	padding: $bsu ($tsu + 5px);
	position: relative;
	z-index: 1;
	transition: all ease-in 0.3s;


	@include bp("large") {
		@include font-heading-28b;
		padding: 45px 60px;
	}

	&:hover {
		transition: all ease-in 0.3s;
	}
}

.c-nature-details__pagination-prev-text {
	@include font-base-12-tall;
	line-height: 1.4;

	@include bp("large") {
		@include font-base-14-tall;
	}
}