// COMPONENT.BASIC-FILTER



.c-basic-filter {
	@include list-reset;

	display: flex;
	justify-content: center;

	margin: ($bsu - $tsu) 0;
}

.c-basic-filter__item {
	padding: $tsu;
}

.c-basic-filter__button {
	@include button("small");
}
