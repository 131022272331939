﻿.c-two-column {
	@include control;
}

.c-two-column__layout-wrapper {
	@include layout-wrapper;
}

.c-two-column__layout {
	display: flex;
	flex-wrap: wrap;
	margin-left: -$lsu;
}

.c-two-column__column {
	padding-left: $lsu;
	width: 100%;

	@include bp("large") {
		width: 50%;
	}

	&:nth-child(n+1) {
		> :first-child {
			margin-top: 0;
		}
	}

	> :last-child {
		margin-bottom: 0;
	}
}
