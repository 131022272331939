﻿// Modal



$people-modal-bp: 650px;

.c-feature-modal {
	display: flex;
	justify-content: flex-end;

	@include bp-below($people-modal-bp) {
		flex-wrap: wrap;
	}

	@include bp($people-modal-bp) {
		height: 100%;
	}

	@include bp-below("medium") {
		padding-top: ($bsu + $ssu);
	}
}

.c-feature-modal__body {
	@include bp($people-modal-bp) {
		width: calc(100% / 3 * 2);
		max-height: 100%;
		overflow: auto;
	}

	@include bp("extra-large") {
		width: 40vw;
	}

	position: relative;
	padding: $lsu $bsu $lsu;

	&:focus {
		// Only receives focus when modal is opened on on click, shouldn't need to be indicated
		outline: none;
	}

	@include bp-below("medium") {
		position: static;
		padding: $bsu $ssu $bsu;
		order: 1;
	}
}

.c-feature-modal__close {
	@include button-reset;
	position: absolute;
	top: $ssu;
	right: $bsu;

	display: flex;
	align-items: center;
	padding: $ssu;

	@include font-base-16sb;
	@include link-transition;

	@include bp-below("medium") {
		top: 0;
		right: 0;
		padding: 17px $ssu;
	}
}

.c-feature-modal__close-icon {
	margin-left: $ssu;
}

.c-feature-modal__title {
	display: block;
	@include font-heading-28b;

	@include bp("large") {
		@include font-heading-36b;
	}

	+ .c-feature-modal__subtitle {
		margin-top: $tsu;
	}
}

.c-feature-modal__subtitle {
	display: block;

	@include font-base-18m--tall;

	+ .c-feature-modal__title {
		margin-top: $tsu;
	}
}

.c-feature-modal__text {
	@include body-text;
	display: block;
	margin-top: $ssu;

	@include bp("large") {
		margin-top: $bsu;
	}
}

.c-feature-modal__link {
	margin-top: $bsu;

	@include bp("large") {
		margin-top: $lsu;
	}
}

.c-feature-modal__media {
	width: 100%;

	@include bp($people-modal-bp) {
		width: calc(100% / 3);
		min-width: 300px;
	}

	position: relative;
}

.c-feature-modal__image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: middle right;
}

.c-feature-modal__cta-list {
	margin-top: $bsu;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.c-feature-modal__cta {
	margin-top: $ssu;
	display: inline-flex;
	align-items: center;
}

.c-feature-modal__cta-text {
	@include font-base-24b;
}

.c-feature-modal__cta {
}

.c-feature-modal__cta-icon {
	@include circle(35px);
	flex-shrink: 0;
	margin-right: $ssu;
	border: 1px solid var(--secondary-a);
	display: flex;
	justify-content: center;
	align-items: center;
}
