// COMPONENT.HERO-NEWS

$hero-bp: "large";

.c-hero-projects {
	position: relative;
	background-color: $grey--lightest;
	margin-bottom: 0;

	@include bp("medium") {
		margin-bottom: $bsu;
	}
}

.c-hero__primary {
	.c-hero-projects & {
		background-color: $grey--lightest;
		background-image: url("/content/images/interface/bg/white-circles-pattern-quarter-left.svg");
		background-position: bottom left;
		background-repeat: no-repeat;
		margin-bottom: 0;


		@include bp("large") {
			margin-bottom: 0;
		}

		@include bp-below("large") {
			background-size: 200px;
		}
	}
}

.c-hero__primary .c-hero__body {
	.c-hero-projects & {
		margin-bottom: 0;

		@include bp-below("large") {
			padding: 0;
		}

		@include bp("large") {
			margin-bottom: 0;
		}
	}
}

.c-hero__primary .c-hero__figure {
	.c-hero-projects & {
		@include bp("large") {
			height: 100vh;
			max-height: 740px;
			overflow: hidden;
		}
	}
}

.c-hero-projects__title {
	@include font-heading-28b;
	margin-bottom: ($ssu * 1.5);

	@include bp("large") {
		@include font-heading-40b;
		margin-bottom: $bsu;
	}

	@include bp("extra-large") {
		@include font-heading-52b;
	}
}

.c-hero-projects__tag {
	@include font-base-12mu;
	display: inline-block;
	padding: 3px $ssu;
	margin-bottom:$bsu;
}


.c-hero-projects__intro {
	@include font-base-18;
	line-height: lh(28, 18);
	margin-bottom: $ssu;

	@include bp("large") {
		@include font-base-20;
		line-height: lh(32, 20);
	}
}

.c-hero-projects-cta {
	@include button("medium");
	padding-left: $bsu;
	padding-right: $bsu;
	border: 1px solid var(--primary-a);
	margin-top: $ssu;
}