$news-bp: "medium";

.c-events {
	&:not(.c-event--grey-background) {
		@include control;
	}

	&.c-event--grey-background {
		@include control("grey");

		@include bp($news-bp) {
			&.c-event--circle-pattern {
				background-image: url("/content/images/interface/bg/white-circles-pattern-quarter-left.svg");
				background-position: bottom left;
				background-repeat: no-repeat;
			}
		}

		.l-page__footer-controls & {
			@include bp("large") {
				padding-top: ($bsu * 3);
				padding-bottom: ($bsu * 3);
			}
		}
	}
}

.c-events__layout {
	@include layout-wrapper;
}

.c-events__header {
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	margin-bottom: $bsu;
}

.c-events__heading {
	@include control__heading;
	margin-bottom: 0;
}

.c-events__all-link-wrapper {
	display: flex;

	@include bp-below($news-bp) {
		margin-top: $bsu;
		justify-content: center;
	}

	@include bp($news-bp) {
		justify-content: flex-end;
		// Adjust for line height, to align bottom with heading
		margin-bottom: 3px;
	}

	&:first-child:last-child {
		width: 100%;
	}
}

.c-events__all-link-wrapper--mobile {
	@include bp($news-bp) {
		display: none;
	}
}

.c-events__all-link-wrapper--desktop {
	@include bp-below($news-bp) {
		display: none;
	}
}

.c-events__all-link {
	display: flex;
	align-items: center;

	@include font-base-20sb;

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;
		padding-left: 0.5em;
		color: var(--secondary-a);
	}
}

.c-events__body {}

.c-events__list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	margin-left: -$ssu;
	margin-top: -$ssu;
}

.c-events__list-item {
	padding-left: $ssu;
	padding-top: $ssu;
	width: 100%;

	@include bp("medium") {
		width: 50%;
	}

	@include bp("large") {
		width: 25%;
	}
}

@supports (display: grid) {
	.c-events__list {
		display: grid;
		margin: 0;
		grid-gap: $ssu;
		grid-template-columns: 100%;

		@include bp("medium") {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@include bp("large") {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}
	}

	.c-events__list-item {
		padding-left: 0;
		padding-top: 0;
	}
}

.c-pod--half {
	.c-events__list & {
		@include bp("large") {
			grid-column: span 2;
		}
	}
}

.c-events__list-item__body {
	.c-pod-flip__front {
		position: relative;
	}

	.c-pod-flip__back {
		justify-content: flex-start;
	}
}

.c-events__list-item__heading {
	@include font-heading-20b;

	@include bp("large") {
		@include font-heading-28b;
	}

	.c-events__list-item:hover .c-pod-flip__front & {
		opacity: 0;
	}
}

.c-events__list-item__cat {
	@include font-base-12mu;
	position: absolute;
	top: $ssu;

	.c-events__list-item:hover & {
		opacity: 0;
	}
}

.c-events__list-item__meta {
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	background-color: rgba($primary-a, 0.8);
	flex-direction: column;
	min-width: 82px;

	.c-events__list-item:hover & {
		opacity: 0;
	}
}

.c-events__list-item__meta-date {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: $tsu;
}

.c-events__list-item__date {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.c-events__list-item__date-day {
	@include font-highlights-36;
}

.c-events__list-item__date-month {
	@include font-base-12sbu;
}

.c-events__list-item__date-year {
	@include font-base-12sb;
}

.c-events__list-item__date-dash {
	display: inline-block;
	margin: $tsu;
}

.c-events__list-item__sub-event {
	@include font-base-12mu;
	padding: 3px calc($tsu / 2);
	text-align: center;
}

.c-events__list-item__text {
	margin-top: $ssu;
	@include font-base-16;
}

.c-events__list-item__icon {
	display: inline-block;
	margin-top: auto;
	margin-left: auto;
	font-size: 25px;
}

//////////////
// LINK POD //
//////////////
.c-events__list-link-pod {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;

	padding: $lsu $bsu;

	height: 100%;

	background: $white;
	position: relative; // Needed to go above the listing's grey background
}

.c-events__list-link-pod-title {
	display: block;

	@include font-heading-28b;
}

.c-events__list-link-pod-text {
	display: block;
	margin-top: $lsu;

	@include font-base-16sb;

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;

		display: inline-block;
		vertical-align: middle;
		margin-left: $tsu;

		color: var(--secondary-a);
	}
}