// COMPONENT.HERO-NEWS

$hero-bp: "large";

.c-hero-news {
	position: relative;
	background-color: $grey--lightest;
	margin-bottom: 0;
}

.c-hero__primary {
	.c-hero-news & {
		background-color: $grey--lightest;
		background-image: url("/content/images/interface/bg/white-circles-pattern-quarter-left.svg");
		background-position: bottom left;
		background-repeat: no-repeat;
		margin-bottom: 0;


		@include bp("large") {
			margin-bottom: 0;
		}

		@include bp-below("large") {
			background-size: 200px;
		}
	}
}

.c-hero__primary .c-hero__body {
	.c-hero-news & {
		margin-bottom: 0;

		@include bp-below("large") {
			padding: 0;
		}

		@include bp("large") {
			margin-bottom: 0;
		}
	}
}

.c-hero__primary .c-hero__figure {
	.c-hero-news & {
		@include bp("large") {
			height: 100vh;
			max-height: 740px;
			overflow: hidden;
		}
	}
}

.c-hero-news__meta {
	@include font-base-18;
	display: flex;
	align-items: center;
	margin-bottom: calc($tsu / 2);
}

.c-hero-news__meta-divider {
	width: 1px;
	display: inline-block;
	height: 18px;
	margin: 0 $tsu;
}

.c-hero-news__author {
	display: block;
	margin-bottom: $ssu;

	@include font-base-18;
}

.c-hero-news__title {
	@include font-heading-28b;
	margin-top: $ssu;
	&:first-child {
		margin-top: 0;
	}
	margin-bottom: ($ssu * 1.5);

	@include bp("large") {
		@include font-heading-40b;
		margin-bottom: $ssu;
	}

	@include bp("extra-large") {
		@include font-heading-52b;
	}
}

.c-hero-news__intro {
	@include font-base-18;
	line-height: lh(28, 18);

	@include bp("large") {
		@include font-base-20;
		line-height: lh(32, 20);
	}

	a {
		@include link-brand;
		@include font-base-18sb;

		@include bp("large") {
			@include font-base-20sb;
		}
	}
}
