// CONTROL.ROTATOR



.c-rotator {
	@include control;
}

.c-rotator {
	@include layout-wrapper;
}

.c-rotator__heading {
	@include control__heading;
}

.c-rotator__viewport {
	position: relative;
	overflow: hidden;
	height: 0;
	padding-bottom: calc(394 / 1400 * 100%);
}

.c-rotator__items {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.c-rotator__item {
	position: relative;
}

.c-rotator__figure {
	@include figure;
}

.c-rotator__body {
	position: absolute;
	left: $bsu;
	bottom: $bsu;
	padding: $bsu;
	color: $white;
	background: $brand;

	> *:last-child {
		margin-bottom: 0;
	}
}
