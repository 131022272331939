$news-bp: "medium";

.c-events-listing {
	@include control;
}
// Second block to ensure order is maintained when compiling
.c-events-listing {
	.l-page__main-controls &:first-child {
		margin-top: 0;
	}

	@include bp($news-bp) {
		background-image: url("/content/images/interface/bg/white-circles-pattern-quarter-right.svg");
		background-position: bottom right;
		background-repeat: no-repeat;
	}
}

.c-events-listing__layout {
	@include layout-wrapper;
}

.c-events-listing__header {
	display: block;
	padding: $bsu 0 $bsu;

	@include bp("large") {
		padding: $hsu 0 $bsu;
	}
	background: $grey--light;
}

.c-events-listing__header-layout {
	@include layout-wrapper;
}

.c-events-listing__heading {
	@include font-heading-28b;

	@include bp("large") {
		@include font-heading-52b;
	}
}

.c-events-listing__no-results {
	// Ensure it appears above the background
	position: relative;
}

.c-events-listing__filter-controls {
	padding-left: $bsu;
	padding-top: $bsu;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	width: 100%;
	flex-direction: column;

	@include bp("medium") {
		flex-direction: row;
	}

	@include bp("large") {
		width: 50%;
		flex-direction: column;
	}

	@include bp("extra-large") {
		flex-direction: row;
	}
}

.c-events-listing__filter-button-wrapper {
	background-color: $white;
	display: flex;
	border-radius: 2px;
	width: 100%;
	margin-bottom: calc($tsu * 1.5);

	@include bp("medium") {
		margin-right: $tsu;
		margin-bottom: 0;
	}

	@include bp("large") {
		margin-right: 0;
		margin-bottom: calc($tsu * 1.5);
	}

	@include bp("extra-large") {
		width: auto;
		margin-right: $tsu;
		margin-bottom: 0;
	}
}

.c-events-listing__filter-button {
	@include button("medium");
	@include font-base-14;
	display: flex;
	white-space: nowrap;
	align-items: center;
	@include link-transition;
	width: 50%;
	padding: 14px 10px;

	@include bp("medium") {
		@include font-base-16m;
		padding: 14px 27px;
	}

	@include bp("extra-large") {
		width: auto;
	}
}

.c-events-listing__filter-toggle {
	align-items: center;
	justify-content: center;
	align-content: center;
	width: 100%;

	@include bp("medium") {
		width: auto;
	}

	@include bp("large") {
		width: 100%;
	}
}


//////////
// LIST //
//////////
.c-events-listing__list-background {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: -1px; // Ensure no rounding/anti-aliasing issues can occur
		height: 101px;
		background: $grey--light;
	}
}

.c-events-listing__list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	margin-left: -$ssu;
	margin-top: -$ssu;
}

@supports (display: grid) {
	.c-events-listing__list {
		display: grid;
		margin: 0;
		grid-gap: $ssu;
		grid-template-columns: 100%;

		@include bp("medium") {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@include bp("large") {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}
	}

	.c-events__list-item {
		&:nth-child(5) {
			.c-events-listing__list & {
				@include bp("large") {
					grid-column: span 2;
				}
			}
		}
	}
}

