// OBJECT.GRID

@mixin grid ($gutter: $bsu) {
	display: flex;
	flex-wrap: wrap;

	padding: 0;
	margin: 0;
	list-style: none;

	margin-left: -$gutter;
}

.o-grid {
	@include grid;
}

.o-grid__item {
	width: 100%;
	padding-left: $bsu;
}

.o-grid-v {
	margin-top: -$bsu;

	> .o-grid__item {
		padding-top: $bsu;
	}
}
