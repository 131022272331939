﻿// MACRO.BLOCKQUOTE

.m-quote {
	@include body-text__figure;
}

.m-quote__text {
	@include font-heading-24b;

	&::before {
		@include font-icon;
		content: $iconf-Quote;
		padding-bottom: 0.5em;
		display: block;
		color: var(--secondary-a);
		font-size: 40px;
	}
}

.m-quote__citation {}
