﻿// COMPONENT.BREADCRUMB

.c-breadcrumb {
	@include layout-wrapper;
	margin-top: $lsu;
	margin-bottom: $bsu;
}

.c-breadcrumb__list {
	@include list-reset;
	@include list-inline;

	margin-left: calc($tsu / 2 * -1);
}

.c-breadcrumb__item {
	padding-left: calc($tsu / 2);
	&::after {
		padding-left: calc($tsu / 2);
		color: $grey;
	}

	color: $green;
}

.c-breadcrumb__link {
	@include font-base-14;
	color: inherit;
}

.c-breadcrumb__current {
	@include font-base-14;
	@include link-plain;
}
