// COMPONENT.EVENT-HERO

$hero-bp: "large";

.c-hero__event {
	position: relative;
	//display: flex;
	//flex-direction: column;
	background-color: $grey--lightest;
	margin-bottom: 0;

	@include bp("medium") {
		margin-bottom: $bsu;
		//flex-direction: row;
		//max-height: 740px;
		//overflow: hidden;
	}
}

.c-hero__event-figure {
	@include figure;
	width: 100%;

	@include bp("medium") {
		order: 1;
		width: 50%;
	}
}

.c-hero__primary {
	.c-hero__event & {
		background-color: $grey--lightest;
		background-image: url("/content/images/interface/bg/white-circles-pattern-quarter-left.svg");
		background-position: bottom left;
		background-repeat: no-repeat;
		margin-bottom: 0;

		@include bp("large") {
			margin-bottom: 0;
		}

		@include bp-below("large") {
			background-size: 200px;
		}
	}
}

.c-hero__primary .c-hero__body {
	.c-hero__event & {
		margin-bottom: 0;

		@include bp-below("large") {
			padding: 0;
		}

		@include bp("large") {
			margin-bottom: 0;
		}
	}
}

.c-hero__primary .c-hero__figure {
	.c-hero__event & {
		@include bp("large") {
			height: 100vh;
			max-height: 650px;
			overflow: hidden;
		}

		@include bp("extra-large") {
			max-height: 740px;
		}
	}
}

.c-hero__event-primary {
	width: 100%;
	background-color: $grey--lightest;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: $bsu;
	background-image: url("/content/images/interface/bg/white-circles-pattern-quarter-left.svg");
	background-position: bottom left;
	background-repeat: no-repeat;

	@include bp("medium") {
		order: 0;
		width: 50%;
	}

	@include bp("extra-large") {
		padding-left: $lsu;
	}
}

.c-hero__event-body {
	width: 100%;
	padding-right: $bsu;
	padding-top: $bsu;
	padding-bottom: $bsu;

	@include layout-split(left, 50%);
}

.c-hero__event__category {
	@include font-base-16u;
	display: inline-block;
	margin-bottom: $ssu;

	@include bp("extra-large") {
		@include font-base-18--tall;
	}
}

.c-hero__event__title {
	@include font-heading-28b;
	margin-bottom: ($ssu * 1.5);

	@include bp("large") {
		@include font-heading-40b;
		margin-bottom: $ssu;
	}

	@include bp("extra-large") {
		@include font-heading-52b;
		margin-bottom: $bsu;
	}
}

.c-hero__event__detail-heading-wrapper {
	display: flex;
	align-items: center;
}

.c-hero__event__detail-heading {
	@include font-base-16mu;
}

.c-hero__event__sbn-event {
	@include font-base-12mu;
	padding: 3px $tsu;
	margin-left: $ssu;
}

.c-hero__event-details-wrapper {
	padding-left: ($ssu * 1.5);
	border-left: 2px solid var(--secondary-a);
	display: flex;
	flex-wrap: wrap;
	margin-left: -$ssu;
	margin-top: $bsu;

	@include bp("large") {
		padding: $tsu 0 $ssu ($ssu * 1.5);
	}

	@include bp("extra-large") {
		padding: $ssu 0 $ssu ($ssu * 1.5);
	}
}

.c-hero__event-details {
	padding-left: $ssu;
	width: 100%;

	@include bp("extra-large") {
		width: 50%;
	}

	&.c-hero__event-detail-btn {
		width: 100%;
	}
}

@supports (display: grid) {
	.c-hero__event-details-wrapper {
		display: grid;
		margin: $ssu 0 0;
		grid-gap: $ssu;
		grid-template-columns: 100%;

		@include bp("extra-large") {
			margin: $bsu 0 0;
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
	}

	.c-hero__event-details {
		padding-left: 0;
		padding-top: 0;
		width: 100%;

		@include bp("medium") {
			width: 100%;
		}

		&.c-hero__event-detail-btn {


			@include bp("extra-large") {
				grid-column: span 2;
			}
		}
	}
}

.c-hero__event-detail-name {
	@include font-heading-20b;
	display:block;
	margin-bottom: $tsu;
}

.c-hero__event-detail-value {
	@include font-base-18;
	display: block;
	//margin-bottom: $tsu;
	> p {
		margin-bottom: 0;
	}

	@include bp("extra-large") {
		@include font-base-18;
	}
}

.c-hero__event-detail-cta {
	@include button("medium");
	padding-left: $bsu;
	padding-right: $bsu;
	border: 1px solid var(--primary-a);
	margin-top: $ssu;
}