@mixin news-list-item-small {
	.c-news-list-item__image--small {
		display: block;
	}

	.c-news-list-item__date {
		// Adjust bottom padding for line height
		margin: $ssu 0 ($ssu - 7px);
	}

	.c-news-list-item__heading {
		@include font-heading-20b;
		&:first-child {
			margin: $ssu 0 0;
		}
	}
}

@mixin news-list-item-large {
	.c-news-list-item__image--large {
		display: block;
	}

	.c-news-list-item__date {
		// Adjust bottom padding for line height
		margin: $bsu 0 ($bsu - 7px);
	}

	.c-news-list-item__heading {
		@include font-heading-28b;
		margin: $ssu 0;
	}

	.c-news-list-item__abstract {
		display: block;
	}

	.c-news-list-item__read-more {
		display: flex;
	}
}

@mixin news-list-item($size) {
	@if ($size == "small") {
		@include news-list-item-small;
	}
	@else if ($size == "large") {
		@include news-list-item-large;
	}
}

.c-news-list-item {}

.c-news-list-item__link {
	display: block;

	&:hover,
	&:focus {
		.c-news-list-item__image {
			transform: scale(1.05);
		}
	}
}

.c-news-list-item__media {
	overflow: hidden;
	position: relative;
}

.c-news-list-item__category {
	position: absolute;
	z-index: 1;
	top: $ssu;
	left: $ssu;

	@include font-base-12sbu;
	color: $white;
	text-shadow: 0 0 4px rgba($black, 0.4);
}

.c-news-list-item__image {
	display: none;

	@include link-transition(transform);
}

.c-news-list-item__body {
	padding: 0 0 $ssu;
}

.c-news-list-item__date {
	display: block;
	@include font-base-16;
}

.c-news-list-item__heading {}

.c-news-list-item__abstract {
	display: none;

	@include font-base-16;
}

.c-news-list-item__read-more {
	display: none;

	margin-top: $bsu;
	align-items: center;

	@include font-base-20sb;

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;

		padding-left: 0.5em;

		color: var(--secondary-a);
	}
}
